import React, { memo } from "react";

import { Link } from "react-router-dom";

const SubMenu2 = memo(() => {
  return (
    <>
      <Link
        to="pricing"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Pricing
      </Link>
      <Link
        to="members"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Members
      </Link>
      <Link
        to="about"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        About
      </Link>
      <Link
        to="contact"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Contact
      </Link>
      <Link
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300"
        to="faq"
      >
        FAQ
      </Link>
    </>
  );
});

export default SubMenu2;
