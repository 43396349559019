import React, { memo } from "react";
import WebHomeNavbar from "./WebHomeNavbar";
import Overview from "./web services/Overview";
import Background from "./web services/Background";
import History from "./web services/History";
import StaticWebsite from "./web services/StaticWebsite";
import DynamicWebsite from "./web services/DynamicWebsite";
import MultimediaContent from "./web services/MultimediaContent";
import TableOfContent from "./web services/TableOfContent";
const WebHome = memo(() => {
  return (
    <>
      <WebHomeNavbar />
      <div className="mr-6">
        <Overview />
        <Background />
        <History />
        <StaticWebsite />
        <DynamicWebsite />
        <MultimediaContent />
        <TableOfContent initialState={true} />
      </div>
    </>
  );
});

export default WebHome;
