import React, { memo } from "react";
import Logo from "./logo/Logo";
import Menu from "./menu/Menu";
import ToggleMenu from "./menu/ToggleMenu";
import { useSelector } from "react-redux";

const Navbar = memo(() => {
  const menu = useSelector((state) => state.toggle.menu);
  return (
    <>
      <div className="grid grid-cols-12 gap-2 items-center py-4 w-full 3xl:w-[1400px] mx-auto">
        <div className="col-span-12 lg:col-span-3 flex items-center justify-around mb-1 lg:mb-0">
          <Logo />
          <div className="text-lg lg:hidden">
            <ToggleMenu />
          </div>
        </div>
        <div
          className={`${
            menu ? "" : "hidden"
          } col-span-12 lg:col-span-9 lg:inline-block`}
        >
          <Menu />
        </div>
      </div>
    </>
  );
});

export default Navbar;
