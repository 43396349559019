import React from "react";

function RightArrow({ className }) {
  return (
    <>
      <svg viewBox="0 0 3 6" className={className}>
        <path d="M0 0L3 3L0 6"></path>
      </svg>
    </>
  );
}

export default RightArrow;
