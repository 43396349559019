import React, { memo } from "react";
import TeamHeader from "./team header/TeamHeader";
import TeamCard from "./team card/TeamCard";

const TeamMembersBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <TeamHeader />
        <TeamCard />
      </div>
    </>
  );
});

export default TeamMembersBody;
