import React, { memo } from "react";
import SubMenu1 from "./sub footer menu/SubMenu1";
import SubMenu2 from "./sub footer menu/SubMenu2";
import SubFooterServices from "./sub footer services/SubFooterServices";

const SubFooterContent = memo(() => {
  return (
    <>
      <div className="bg-gray-200 p-2">
        <div className="3xl:w-[1400px] mx-auto grid grid-flow-row xl:grid-cols-12 xl:gap-10 xl:justify-around xl:items-center md:ring-2 m-2 p-2 rounded-md ">
          <div className="lg:flex lg:justify-around lg:items-center xl:col-span-9">
            <div className=" flex flex-col justify-center items-center ring-2 p-1 md:ring-0 rounded-md">
              <div className="p-1 font-semibold text-lg">
                {process.env.REACT_APP_NAME}
              </div>
              <div className="text-center">
                H-129, Block B, Alpha I, Greater Noida, Uttar Pradesh - 201310
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mt-2">
              <div className="border-b-2 border-gray-300 font-medium">
                Useful Links
              </div>
              <div className="flex gap-16 p-2">
                <div className="flex flex-col ">
                  <SubMenu1 />
                </div>
                <div className="flex flex-col ">
                  <SubMenu2 />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center xl:col-span-3 ">
            <div className="flex flex-col">
              <div className="flex mb-2">
                <div className="border-b-2 border-gray-300 font-medium">
                  Our Services
                </div>
              </div>
              <SubFooterServices />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SubFooterContent;
