import React, { memo } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./site/Home";
import Details from "./site/Details";
import Web from "./components/services/details/Web";
import Application from "./components/services/details/Application";
import RestApi from "./components/services/details/RestApi";
import Debugging from "./components/services/details/Debugging";
import Cloud from "./components/services/details/Cloud";
import Cpanel from "./components/services/details/Cpanel";
import Static from "./components/pricing/costing/Static";
import Dynamic from "./components/pricing/costing/Dynamic";
import Ecommerce from "./components/pricing/costing/Ecommerce";
import MemberDetails from "./components/teams/member details/MemberDetails";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Projects from "./pages/Projects";
import Technology from "./pages/Technology";
import Pricing from "./pages/Pricing";
import TeamMembers from "./pages/TeamMembers";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Welcome from "./pages/Welcome";
import ServiceDetails from "./pages/ServiceDetails";
import FAQ from "./pages/FAQ";
import ProductManagement from "./components/services/details/ProductManagement";
import GraphicDesign from "./components/services/details/GraphicDesign";
import PricingDetails from "./pages/PricingDetails";
import WebHome from "./components/services/details/web/WebHome";
import StaticWeb from "./components/services/details/web/web services/StaticWeb";
import DynamicWeb from "./components/services/details/web/web services/DynamicWeb";
import EcommerceWeb from "./components/services/details/web/web services/EcommerceWeb";
import WebServices from "./components/services/details/web/WebServices";
import Overview from "./components/services/details/web/web services/Overview";
import Background from "./components/services/details/web/web services/Background";
import History from "./components/services/details/web/web services/History";
import StaticWebsite from "./components/services/details/web/web services/StaticWebsite";
import DynamicWebsite from "./components/services/details/web/web services/DynamicWebsite";
import MultimediaContent from "./components/services/details/web/web services/MultimediaContent";
import TableOfContent from "./components/services/details/web/web services/TableOfContent";
import Error404 from "./errors/Error404";
import Error from "./errors/Error";
import UserProtectedRoutes from "./routes/UserProtectedRoutes";

const App = memo(() => {
  return (
    <>
      <Routes>
        <Route element={<Error />}>
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route element={<UserProtectedRoutes />}>
          <Route element={<Details />}>
            <Route path="/home" element={<Welcome />} />
            <Route element={<UserProtectedRoutes />}>
              <Route element={<ServiceDetails />}>
                <Route path="/services" element={<Services />} />
                <Route element={<UserProtectedRoutes />}>
                  <Route element={<Web />}>
                    <Route path="/services/web" element={<WebHome />} />
                    <Route element={<WebServices />}>
                      <Route
                        path="/services/web/overview"
                        element={<Overview />}
                      />
                      <Route
                        path="/services/web/background"
                        element={<Background />}
                      />
                      <Route
                        path="/services/web/history"
                        element={<History />}
                      />
                      <Route
                        path="/services/web/static-website"
                        element={<StaticWebsite />}
                      />
                      <Route
                        path="/services/web/dynamic-website"
                        element={<DynamicWebsite />}
                      />
                      <Route
                        path="/services/web/multimedia"
                        element={<MultimediaContent />}
                      />
                      <Route
                        path="/services/web/table-of-content"
                        element={<TableOfContent initialState={false} />}
                      />
                      <Route
                        path="/services/web/static"
                        element={<StaticWeb />}
                      />
                      <Route
                        path="/services/web/dynamic"
                        element={<DynamicWeb />}
                      />
                      <Route
                        path="/services/web/e-commerce"
                        element={<EcommerceWeb />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route path="/services/app" element={<Application />} />
                <Route path="/services/api" element={<RestApi />} />
                <Route path="/services/debugging" element={<Debugging />} />
                <Route path="/services/cloud" element={<Cloud />} />
                <Route path="/services/cpanel" element={<Cpanel />} />
                <Route
                  path="/services/product-management"
                  element={<ProductManagement />}
                />
                <Route
                  path="/services/graphic-design"
                  element={<GraphicDesign />}
                />
              </Route>
            </Route>
            <Route path="/products" element={<Products />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/technology" element={<Technology />} />
            <Route element={<UserProtectedRoutes />}>
              <Route element={<PricingDetails />}>
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/pricing/static" element={<Static />} />
                <Route path="/pricing/dynamic" element={<Dynamic />} />
                <Route path="/pricing/ecommerce" element={<Ecommerce />} />
              </Route>
            </Route>
            <Route path="/members" element={<TeamMembers />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />

            <Route path="/member/:name" element={<MemberDetails />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
});

export default App;
