import React from "react";
import RightArrow from "../../../../svg/RightArrow";

function CardOutline() {
  return (
    <>
      <div className="absolute xs:top-[198px] xlg:top-[259px]  xs:left-[120px] sm:left-44 md:left-52 lg:left-72 xl:left-[292px]  xs:border-t-8 xlg:border-t-4 border-gray-300 xs:w-14 xlg:w-9 xs:rotate-90 xlg:-rotate-45 xl:-rotate-45"></div>
      <div className="absolute xs:top-40 xlg:top-60  xs:left-[143px] sm:left-[199px] md:left-[231px] xlg:left-[238px] lg:left-[318px] xl:left-[322px]  xs:-rotate-90 xlg:-rotate-45 xl:-rotate-45">
        <RightArrow className="xs:h-5 xlg:h-3 fill-gray-400" />
      </div>
      <div className="absolute xs:top-[222px] xlg:top-[269px]  xs:left-[143px] sm:left-[199px] md:left-[231px] xlg:left-52 lg:left-72 xl:left-[292px]">
        <RightArrow className="xs:h-5 xlg:h-3 fill-gray-400 xs:rotate-90 xlg:rotate-[135deg] xl:rotate-[135deg]" />
      </div>

      <div className="absolute xs:bottom-[275px] xlg:top-[258px] xs:left-[120px] sm:left-44 md:left-52 xlg:left-[360px] lg:left-[421px] xl:left-[685px] xs:border-t-8 xlg:border-t-4 border-gray-300 xs:w-14 xlg:w-9 xs:rotate-90 xlg:rotate-45"></div>
      <div className="absolute xs:bottom-[300px] xlg:top-[250px]  xs:left-[143px] sm:left-[199px] md:left-[231px] xlg:left-[356px] lg:left-[417px] xl:left-[681px] xs:-rotate-90  xlg:-rotate-[135deg]">
        <RightArrow className="xs:h-5 xlg:h-3 fill-gray-400" />
      </div>
      <div className="absolute xs:bottom-60  xlg:top-[268px] xs:left-[143px] sm:left-[199px] md:left-[231px] xlg:left-[389px] lg:left-[450px] xl:left-[714px]">
        <RightArrow className="xs:h-5 xlg:h-3 fill-gray-400 xs:rotate-90 xlg:rotate-45" />
      </div>

      <div className="absolute xlg:bottom-24 xlg:left-[298px] lg:left-[370px] xl:left-[454px] xlg:border-t-4 border-gray-300 xlg:w-2 xl:w-[106px] hidden xlg:inline-block"></div>
      <div className="absolute xlg:bottom-[92px] xlg:left-[301px] lg:left-[376px] xl:left-[560px]  hidden xlg:inline-block">
        <RightArrow className="h-3 fill-gray-400" />
      </div>
      <div className="absolute xlg:bottom-[92px] xlg:right-[301px] lg:right-[376px] xl:right-[560px] xlg:rotate-180 hidden xlg:inline-block">
        <RightArrow className="h-3 fill-gray-400" />
      </div>
    </>
  );
}

export default CardOutline;
