import React, { memo } from "react";
import PricingBody from "../components/pricing/PricingBody";

const Pricing = memo(() => {
  return (
    <>
      <div id="pricing">
        <PricingBody />
      </div>
    </>
  );
});

export default Pricing;
