import React, { memo } from "react";
import { Link } from "react-scroll";

const Menu1 = memo(() => {
  return (
    <>
      <Link
        to="home"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Home
      </Link>
      <Link
        to="services"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Services
      </Link>
      <Link
        to="products"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Products
      </Link>
      <Link
        to="projects"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Projects
      </Link>
      <Link
        to="technology"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Technology
      </Link>
    </>
  );
});

export default Menu1;
