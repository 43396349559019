import React, { memo } from "react";
import { NavLink } from "react-router-dom";

const FooterServices = memo(() => {
  return (
    <>
      <NavLink className="text-blue-500 hover:scale-105 duration-300" to="/">
        Web Design
      </NavLink>
      <NavLink className="text-blue-500 hover:scale-105 duration-300" to="/">
        Web Development
      </NavLink>
      <NavLink className="text-blue-500 hover:scale-105 duration-300" to="/">
        App Development
      </NavLink>
      <NavLink className="text-blue-500 hover:scale-105 duration-300" to="/">
        Product Management
      </NavLink>
      <NavLink className="text-blue-500 hover:scale-105 duration-300" to="/">
        Graphic Design
      </NavLink>
    </>
  );
});

export default FooterServices;
