function useTableHeaderStyle() {
  const style = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "rgb(103 232 249)",
        color: "#000000",
      },
    },
    rows: {
      style: {
        backgroundColor: "rgb(245 245 245)",
        fontSize: "12px",
        cursor: "pointer",
        borderLeft: "1px solid #dadde1",
      },
    },
    cells: {
      style: {
        padding: "10px",
        borderRight: "1px solid #dadde1",
      },
    },
    subHeader: {
      style: {
        borderBottom: "1px solid rgb(209 213 219 / 0.4)",
        minHeight: "52px",
      },
    },
  };
  return [style];
}

export default useTableHeaderStyle;
