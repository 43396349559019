import React, { memo } from "react";
import { Link } from "react-scroll";

const BannerContent = memo(() => {
  return (
    <>
      <div className=" xl:px-72 2xl:px-80 3xl:px-96 xl:w-full absolute left-1/2 bottom-0 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <div className="flex flex-col gap-1">
          <div className="bg-cyan-100/80 rounded-sm xl:text-3xl">OrianSoft</div>
          <div className="bg-cyan-100/80 rounded-sm xl:text-2xl">
            Software Solution
          </div>
        </div>
        <div className="flex gap-2 justify-center mt-2 text-xs xl:text-base">
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-80}
            duration={1500}
            activeClass="notActive"
          >
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-2 py-0.5 rounded-sm duration-300">
              Contact
            </button>
          </Link>
          <button className="bg-blue-600 hover:bg-blue-700 text-white px-2 py-0.5 rounded-sm duration-300">
            Get Started
          </button>
        </div>
      </div>
    </>
  );
});

export default BannerContent;
