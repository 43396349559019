import React, { memo } from "react";
import RightArrow from "../../../../svg/RightArrow";
import { NavLink } from "react-router-dom";
const WebServicesNavbar = memo(() => {
  const data = [
    {
      menu: "Web Services",
      subMenu: [
        {
          id: "Overview",
          name: "Overview",
        },
        {
          id: "Background",
          name: "Background",
        },
        {
          id: "History",
          name: "History",
        },
        {
          id: "Static-Website",
          name: "Static website",
        },
        {
          id: "Dynamic-Website",
          name: "Dynamic website",
        },
        {
          id: "Multimedia",
          name: "Multimedia and Interactive Content",
        },
        {
          id: "Table-of-Content",
          name: "Table of Content",
        },
      ],
    },
    {
      menu: "Order",
      subMenu: [
        {
          id: "Static",
          name: "Static website",
        },
        {
          id: "Dynamic",
          name: "Dynamic website",
        },
        {
          id: "E-Commerce",
          name: "E-Commerce website",
        },
      ],
    },
  ];
  return (
    <>
      <div className="mb-6 ">
        <div className="mr-6 border-r border-[#dadde1] sticky top-32">
          {data.map((web, index) => (
            <div key={index} className=" my-3 px-3 ">
              <div className="cursor-pointer font-semibold text-base flex justify-between items-center hover:bg-gray-200/80 rounded-md">
                <div className="py-2 ml-2">{web.menu}</div>
                <div className="w-1.5 mr-6 rotate-90">
                  <RightArrow />
                </div>
              </div>
              {web.subMenu.map((subMenu, i) => (
                <div key={i} className="ml-6 flex flex-col flex-wrap w-44 ">
                  <NavLink
                    to={`/services/web/${subMenu.id.toLowerCase()}`}
                    className={({ isActive }) =>
                      isActive
                        ? "bg-blue-600 text-white hover:bg-gray-200/80 hover:text-black rounded-md px-2 py-0.5"
                        : "hover:bg-gray-200/80 px-2 py-1 rounded-md cursor-pointer my-0.5"
                    }
                  >
                    {subMenu.name}
                  </NavLink>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default WebServicesNavbar;
