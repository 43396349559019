import React, { memo, useState } from "react";
import Gear from "../../../svg/Gear";
import LaravelSVG from "../../../svg/LaravelSVG";
import ReactSVG from "../../../svg/ReactSVG";
import TailwindSVG from "../../../svg/TailwindSVG";
import { Link } from "react-router-dom";
const ProjectCard = memo(() => {
  const [technology, setTechnology] = useState(false);
  const [technologyIndex, setTechnologyIndex] = useState(null);
  const handleShowTechnology = (index, e) => {
    e.preventDefault();
    setTechnologyIndex(index);
    setTechnology(true);
  };
  const handleHideTechnology = (index, e) => {
    e.preventDefault();
    setTechnologyIndex(index);
    setTechnology(false);
  };
  const projects = [
    {
      name: "News of Cyber Community",
      image: "project-1.png",
      logo: "project-logo-1.png",
      url: "https://nccbd.pro/",
      about:
        "News of Cyber Community is a Website cum News, Contents and Events Posting System with User end point and Admin end point . Where user can post news and contents after admins approval and admin can approve them and aslo can post events so for user can join that events after event registration.There are so many features we can observe.",
    },
    // {
    //   name: "EveryThings",
    //   image: "project-2.png",
    //   logo: "project-logo-2.png",
    //   url: "https://everythings.oriansoft.com/",
    //   about:
    //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus sequi earum in tempora eos aliquam nulla sit, consequatur non ducimus!Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus sequi earum in tempora eos aliquam nulla sit, consequatur non ducimus!",
    // },
    // {
    //   name: "The News App",
    //   image: "project-3.png",
    //   logo: "project-logo-3.png",
    //   url: "https://news.oriansoft.com/",
    //   about:
    //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus sequi earum in tempora eos aliquam nulla sit, consequatur non ducimus!Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus sequi earum in tempora eos aliquam nulla sit, consequatur non ducimus!",
    // },
    // {
    //   name: "HisDivineGrace",
    //   image: "project-4.png",
    //   logo: "project-logo-4.png",
    //   url: "https://hisdivinegrace.oriansoft.com/",
    //   about:
    //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus sequi earum in tempora eos aliquam nulla sit, consequatur non ducimus!Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus sequi earum in tempora eos aliquam nulla sit, consequatur non ducimus!",
    // },
  ];
  return (
    <>
      <div className="grid grid-cols-12  gap-3 mt-3 p-2 bg-slate-100">
        {projects.map((project, index) => (
          <div
            key={index}
            className="relative bg-white shadow col-span-12 xlg:col-span-6  xl:col-span-4 p-2 pb-4"
          >
            <div className="relative">
              <div
                onMouseOver={(e) => handleShowTechnology(index, e)}
                onMouseOut={(e) => handleHideTechnology(index, e)}
                className="float-right text-blue-700 hover:underline hover:underline-offset-[5px] cursor-pointer mt-2 mr-3"
              >
                Technology
              </div>

              {technologyIndex === index && (
                <>
                  {technology && (
                    <div className="absolute top-28 z-30 h-auto w-full bg-gray-300">
                      <div className="grid grid-cols-12 gap-2 m-2">
                        <div className="flex gap-2 col-span-12 bg-white  rounded-md p-2">
                          <div className="flex-none w-14 h-14 rounded-full bg-white ring-1 ring-slate-900/5 shadow flex items-center justify-center overflow-hidden">
                            <LaravelSVG className="fill-[#FF2D20]" />
                          </div>
                          <div className="">
                            <div className="mb-2 leading-2 text-slate-900 font-semibold ">
                              <div className="before:absolute before:-inset-3 before:rounded-2xl">
                                Laravel
                              </div>
                            </div>
                            <p className="text-xs leading-2 text-slate-700">
                              PHP web application framework with expressive,
                              elegant syntax.
                            </p>
                          </div>
                        </div>
                        <div className="relative flex gap-2 col-span-12 bg-white  rounded-md p-2">
                          <div className="flex-none w-14 h-14 rounded-full bg-white ring-1 ring-slate-900/5 shadow flex items-center justify-center overflow-hidden">
                            <ReactSVG className="p-1 animate-spin-forword text-[rgb(8,126,164)]" />
                          </div>
                          <div className="">
                            <div className="mb-2 leading-2 text-slate-900 font-semibold ">
                              <div className="before:absolute before:-inset-3 before:rounded-2xl">
                                React
                              </div>
                            </div>
                            <p className="text-xs leading-2 text-slate-700">
                              CLI tool for scaffolding a new single-page React
                              application.
                            </p>
                          </div>
                        </div>
                        <div className="relative flex gap-2 col-span-12 bg-white  rounded-md p-2">
                          <div className="flex-none p-1.5 w-14 h-14 rounded-full bg-white ring-1 ring-slate-900/5 shadow flex items-center justify-center overflow-hidden">
                            <TailwindSVG className="" />
                          </div>
                          <div className="">
                            <div className="mb-2 leading-2 text-slate-900 font-semibold ">
                              <div className="before:absolute before:-inset-3 before:rounded-2xl">
                                Tailwind CSS
                              </div>
                            </div>
                            <p className="text-xs leading-2 text-slate-700">
                              Rapidly build modern websites without ever leaving
                              your HTML. A utility-first CSS framework packed
                              with classNamees like flex, pt-4, text-center and
                              rotate-90 that can be composed to build any
                              design, directly in your markup.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="border-t-2 border-t-gray-400/80 mt-12 relative">
              <Link
                to={project.url}
                target="_blank"
                className="absolute -top-9 "
              >
                <div className="flex flex-col gap-1 items-center justify-center ml-6">
                  <div className="w-16 h-16 rounded-full bg-white ring-1 ring-slate-900/5 shadow flex items-center justify-center overflow-hidden">
                    <img
                      src={`uploads/projects/logos/${project.logo}`}
                      alt={project.logo}
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="font-semibold">{project.name}</div>
                </div>
              </Link>
            </div>
            <div className="mt-14 relative">
              <div className="py-2 mx-2 text-justify">{project.about}</div>
              <Gear className="absolute top-6 left-6 z-0 stroke-gray-300/40 h-20 rounded-full animate-spin-forword" />
              <Gear className="absolute -top-14 right-6 z-0 stroke-gray-300/40 h-16 rounded-full animate-spin-forword" />
              <Link
                to={project.url}
                target="_blank"
                className="flex float-right mr-6 px-2 py-1 rounded-md bg-blue-600 hover:bg-blue-700 text-white cursor-pointer text-xs duration-300"
              >
                Read more
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
});

export default ProjectCard;
