import React from "react";

function Gear({ className }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 600"
        stroke="black"
        strokeWidth="40"
        fill="none"
        className={className}
      >
        <path
          d="M575,325.7v-50.3c0-10.8-8.4-19.8-19.3-20.5l-44.2-2.7c-13.7-0.8-25.6-9.7-30.5-22.5
	c-0.9-2.4-1.9-4.7-2.9-7c-5.4-12.5-3.3-26.9,5.7-37.1l29.7-34c7.1-8.1,6.7-20.4-0.9-28l-35.5-35.5c-7.7-7.7-20-8-28.1-0.8
	l-32.8,29.1c-10.3,9.1-24.9,11.2-37.4,5.5c-2.5-1.1-5-2.2-7.6-3.2c-12.5-5-21.2-16.7-22.1-30.1l-3-44.5
	c-0.7-10.8-9.7-19.1-20.5-19.1h-49.5c-10.8,0-19.7,8.3-20.4,19l-3.1,43.3c-1,13.6-9.8,25.4-22.6,30.2c-2.9,1.1-5.7,2.2-8.5,3.4
	c-12.4,5.4-26.8,3.2-37-5.7l-32.9-28.8c-8.1-7.1-20.4-6.7-28,0.9l-35.5,35.5c-7.7,7.7-8,20-0.8,28.1l28.1,31.7
	c9.2,10.3,11.3,25,5.6,37.6c-1.2,2.8-2.4,5.6-3.5,8.4c-5,12.7-16.8,21.4-30.5,22.3l-42.8,2.9c-10.8,0.7-19.1,9.7-19.1,20.5v50.3
	c0,10.8,8.4,19.8,19.3,20.5l42.1,2.5c13.7,0.8,25.6,9.6,30.5,22.4c1.1,2.9,2.3,5.8,3.6,8.6c5.5,12.5,3.4,27-5.6,37.2l-28.4,32.5
	c-7.1,8.1-6.7,20.4,0.9,28l35.5,35.5c7.7,7.7,20,8,28.1,0.8l32.1-28.4c10.3-9.1,24.9-11.2,37.4-5.7c2.7,1.2,5.4,2.3,8.1,3.4
	c12.7,4.9,21.4,16.7,22.3,30.3l2.9,43.6c0.7,10.8,9.7,19.1,20.5,19.1h50.3c10.8,0,19.8-8.4,20.5-19.3l2.6-43.6
	c0.8-13.7,9.6-25.5,22.4-30.5c2.5-1,5-2,7.4-3.1c12.5-5.5,27-3.4,37.3,5.6l33.6,29.4c8.1,7.1,20.4,6.7,28-0.9l35.5-35.5
	c7.7-7.7,8-20,0.8-28.1l-29.5-33.3c-9-10.2-11.1-24.7-5.6-37.1c1-2.4,2-4.8,3-7.2c5-12.6,16.7-21.4,30.2-22.3l45.1-3
	C566.6,345.4,575,336.5,575,325.7z M396.5,300c0,53.9-43.7,97.5-97.5,97.5s-97.5-43.7-97.5-97.5s43.7-97.5,97.5-97.5
	S396.5,246.1,396.5,300z"
        />
      </svg>
    </>
  );
}

export default Gear;
