import React, { memo } from "react";
import ContactBody from "../components/contact/ContactBody";

const Contact = memo(() => {
  return (
    <>
      <div id="contact">
        <ContactBody />
      </div>
    </>
  );
});

export default Contact;
