import React, { memo } from "react";
import { Link, Outlet } from "react-router-dom";
const Error = memo(() => {
  const APP_FIRST_NAME = process.env.REACT_APP_NAME;

  return (
    <>
      <div className="flex items-center justify-center ">
        <div className="border border-gray-300/40 rounded-md  w-full shadow pt-3 pb-6">
          <div className="flex justify-around items-center">
            <Link to="/home" className="hover:underline">
              Home
            </Link>
            <Link to="/" className="flex gap-2 items-center cursor-pointer">
              <img
                src="/favicon-96x96.png"
                alt="logo"
                className="w-9 h-9 rounded-full"
              />
              <div className="">
                <span className="text-base font-semibold">
                  {APP_FIRST_NAME}
                </span>
              </div>
            </Link>
          </div>
          <hr className="mb-5 mt-3 border-gray-300/40" />
          <Outlet />
        </div>
      </div>
    </>
  );
});
export default Error;
