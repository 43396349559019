import React, { memo } from "react";
import TechnologyHeader from "./tech header/TechnologyHeader";
import TechnologyCard from "./tech card/TechnologyCard";

const TechnologyBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <TechnologyHeader />
        <TechnologyCard />
      </div>
    </>
  );
});

export default TechnologyBody;
