import React, { memo } from "react";
import ProjectsBody from "../components/projects/ProjectsBody";

const Projects = memo(() => {
  return (
    <>
      <div id="projects">
        <ProjectsBody />
      </div>
    </>
  );
});

export default Projects;
