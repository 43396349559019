import React, { memo } from "react";

import { Link } from "react-router-dom";
import ReactSVG from "../../../../svg/ReactSVG";
import HtmlSVG from "./../../../../svg/HtmlSVG";
import CssSVG from "./../../../../svg/CssSVG";
import JavascriptSVG from "./../../../../svg/JavascriptSVG";
import TailwindSVG from "../../../../svg/TailwindSVG";
import BootStrapSVG from "../../../../svg/BootStrapSVG";
const View = memo(() => {
  return (
    <>
      <div className="relative bg-white border h-60 w-[300px] sm:w-96 rounded-md shadow flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center hover:scale-105 duration-300">
          <Link
            to="https://react.dev/"
            target="_blank"
            className="relative mt-14 bg-gray-700/80  border py-1 w-32 rounded-[4px] shadow flex flex-col gap-1 justify-center items-center"
          >
            <div className="shadow duration-300 bg-slate-200  rounded-full ring-1 ring-slate-200">
              <ReactSVG className="h-10 p-1 animate-spin-forword text-[rgb(8,126,164)]" />
            </div>
            <div className="text-white uppercase font-semibold leading-none">
              React Js
            </div>
            <div className="absolute top-1 right-2 text-white font-semibold font-lusitana">
              UI
            </div>
          </Link>
          <div className="h-4 w-3 bg-gray-300 "></div>
          <div className="h-2 w-12 bg-gray-300 rounded-md "></div>
        </div>
        <Link
          to="https://www.w3schools.com/html/"
          target="_blank"
          className="absolute top-4 left-6 flex flex-col justify-center items-center hover:scale-105 duration-300"
        >
          <div className="uppercase font-semibold leading-none">Html</div>
          <HtmlSVG className="h-10" />
        </Link>
        <Link
          to="https://www.w3schools.com/css/"
          target="_blank"
          className="absolute top-4 flex flex-col justify-center items-center hover:scale-105 duration-300"
        >
          <div className="uppercase font-semibold leading-none">Css</div>
          <CssSVG className="h-10  " />
        </Link>
        <Link
          to="https://www.w3schools.com/js/default.asp"
          target="_blank"
          className="absolute top-4 right-6 flex flex-col justify-center items-center hover:scale-105 duration-300"
        >
          <div className="uppercase font-semibold leading-none">JS</div>
          <JavascriptSVG className="h-10 " />
        </Link>
        <Link
          to="https://tailwindcss.com/"
          target="_blank"
          className="absolute bottom-4 left-6 flex flex-col justify-center items-center hover:scale-105 duration-300"
        >
          <TailwindSVG className="h-9" />
          <div className="uppercase font-semibold leading-none">
            Tailwind Css
          </div>
        </Link>
        <div className="font-semibold mt-1">View</div>
        <Link
          to="https://swiperjs.com/"
          target="_blank"
          className="absolute bottom-4 right-6 flex flex-col justify-center items-center hover:scale-105 duration-300"
        >
          <BootStrapSVG className="h-10 " />
          <div className="uppercase font-semibold leading-none">Bootstrap</div>
        </Link>
      </div>
    </>
  );
});

export default View;
