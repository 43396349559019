import React, { memo, useCallback, useEffect, useState } from "react";
import Header from "../pages/Header";
import Welcome from "../pages/Welcome";
import Services from "../pages/Services";
import Pricing from "../pages/Pricing";
import TeamMembers from "../pages/TeamMembers";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Footer from "../pages/Footer";
import Products from "../pages/Products";
import Projects from "../pages/Projects";
import Technology from "../pages/Technology";
import GoToTop from "../components/scroll to top/GoToTop";
import { useSelector } from "react-redux";
import TeamMemberPhotoModal from "../modal/TeamMemberPhotoModal";
import SubscriptionFormModal from "../modal/SubscriptionFormModal";

const Home = memo(() => {
  const memberPhotoModal = useSelector((state) => state.modal.memberPhotoModal);
  const subscriptionFormModal = useSelector(
    (state) => state.modal.subscriptionFormModal
  );
  const [quickScroll, setQuickScroll] = useState(false);
  const handleNavbarMenuClickOnScroll = useCallback(() => {
    // const width = window.innerWidth;
    const screenSize = document.documentElement.scrollTop;
    if (screenSize <= 100) {
      setQuickScroll(false);
    } else {
      setQuickScroll(true);
    }
    // if (width <= 328) {
    //   dispatch(storeOffset(-150));
    // } else if (width >= 329 && width <= 582) {
    //   dispatch(storeOffset(-130));
    // } else if (width >= 583 && width <= 767) {
    //   dispatch(storeOffset(-105));
    // } else if (width >= 768) {
    //   dispatch(storeOffset(-70));
    // }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleNavbarMenuClickOnScroll);
    return () => {
      window.removeEventListener("scroll", handleNavbarMenuClickOnScroll);
    };
  }, [handleNavbarMenuClickOnScroll]);
  return (
    <>
      <div className="text-sm">
        {subscriptionFormModal && <SubscriptionFormModal />}
        {memberPhotoModal && <TeamMemberPhotoModal />}
        <div className="sticky top-0 left-0 w-[300px] xs:w-full z-50">
          <Header />
        </div>
        <div className="px-2 3xl:px-0 w-[300px] xs:w-full 3xl:w-[1400px] mx-auto">
          <Welcome />
          <Services />
          <Products />
          <Projects />
          <Technology />
          <Pricing />
          <TeamMembers />
          <About />
          <Contact />
        </div>
        <Footer />
        {quickScroll && <GoToTop />}
      </div>
    </>
  );
});

export default Home;
