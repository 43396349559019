import React, { memo } from "react";

const FooterBottom = memo(() => {
  return (
    <>
      <div className=" text-center py-3">
        <span className="font-semibold">
          Copyright © 2022-2030 OrianSoft.com. All rights reserved
        </span>
      </div>
    </>
  );
});

export default FooterBottom;
