import React, { memo } from "react";

const History = memo(() => {
  return (
    <>
      <div
        id="History"
        className="my-3 text-justify text-base flex flex-col gap-4"
      >
        <div className="my-3 text-center text-lg font-semibold">History</div>
        <div className="">
          While "web site" was the original spelling (sometimes capitalized "Web
          site", since "Web" is a proper noun when referring to the World Wide
          Web), this variant has become rarely used, and "website" has become
          the standard spelling.
        </div>
        <div className="">
          All major style guides, such as The Chicago Manual of Style and the AP
          Stylebook, have reflected this change.
        </div>
        <div className="">
          In February 2009, Netcraft, an Internet monitoring company that has
          tracked Web growth since 1995, reported that there were 215,675,903
          websites with domain names and content on them in 2009, compared to
          just 19,732 websites in August 1995. After reaching 1 billion websites
          in September 2014, a milestone confirmed by Netcraft in its October
          2014 Web Server Survey and that Internet Live Stats was the first to
          announce—as attested by this tweet from the inventor of the World Wide
          Web himself, Tim Berners-Lee—the number of websites in the world have
          subsequently declined, reverting to a level below 1 billion.
        </div>
        <div className="">
          This is due to the monthly fluctuations in the count of inactive
          websites. The number of websites continued growing to over 1 billion
          by March 2016 and has continued growing since. Netcraft Web Server
          Survey in January 2020 reported that there are 1,295,973,827 websites
          and in April 2021 reported that there are 1,212,139,815 sites across
          10,939,637 web-facing computers, and 264,469,666 unique domains. An
          estimated 85 percent of all websites are inactive.
        </div>
      </div>
    </>
  );
});

export default History;
