import React, { memo } from "react";
import WebServicesNavbar from "./WebServicesNavbar";
import { Outlet } from "react-router-dom";

const WebServices = memo(() => {
  return (
    <>
      <WebServicesNavbar />
      <div className="mr-6 w-full">
        <Outlet />
      </div>
    </>
  );
});

export default WebServices;
