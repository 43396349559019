import React, { memo } from "react";

const StaticWebsite = memo(() => {
  return (
    <>
      <div
        id="Static"
        className="my-3 text-justify text-base flex flex-col gap-4"
      >
        <div className="my-3 text-center text-lg font-semibold">
          Static website
        </div>
        <div className="">
          A static website is one that has Web pages stored on the server in the
          format that is sent to a client Web browser. It is primarily coded in
          Hypertext Markup Language (HTML); Cascading Style Sheets (CSS) are
          used to control appearance beyond basic HTML. Images are commonly used
          to create the desired appearance and as part of the main content.
        </div>
        <div className="">
          Audio or video might also be considered "static" content if it plays
          automatically or is generally non-interactive. This type of website
          usually displays the same information to all visitors. Similar to
          handing out a printed brochure to customers or clients, a static
          website will generally provide consistent, standard information for an
          extended period of time.
        </div>
        <div className="">
          Although the website owner may make updates periodically, it is a
          manual process to edit the text, photos, and other content and may
          require basic website design skills and software. Simple forms or
          marketing examples of websites, such as a classic website, a five-page
          website or a brochure website are often static websites, because they
          present pre-defined, static information to the user.
        </div>
        <div className="">
          This may include information about a company and its products and
          services through text, photos, animations, audio/video, and navigation
          menus. Static websites may still use server side includes (SSI) as an
          editing convenience, such as sharing a common menu bar across many
          pages. As the site's behavior to the reader is still static, this is
          not considered a dynamic site.
        </div>
      </div>
    </>
  );
});

export default StaticWebsite;
