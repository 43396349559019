import React, { memo } from "react";

const Error404 = memo(() => {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <h1>Error!</h1>
      <h4>404, Page Not Found</h4>
    </div>
  );
});
export default Error404;
