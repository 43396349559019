import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const Menu2 = memo(() => {
  return (
    <>
      <Link
        to="pricing"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Pricing
      </Link>
      <Link
        to="members"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Members
      </Link>
      <Link
        to="about"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        About
      </Link>
      <Link
        to="contact"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Contact
      </Link>
      <NavLink
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300"
        to="/"
      >
        FAQ
      </NavLink>
    </>
  );
});

export default Menu2;
