import React, { memo } from "react";

const ServiceHeader = memo(() => {
  return (
    <>
      <div className="bg-gray-300/80 py-2 text-center text-base rounded-sm">
        Services
      </div>
    </>
  );
});

export default ServiceHeader;
