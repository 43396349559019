import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { openSubscriptionFormModal } from "../../../redux/slices/modalSlice";
import { useDispatch } from "react-redux";

const SubMenu = memo(() => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="uppercase flex flex-wrap xl:flex-nowrap gap-3 lg:gap-3 2xl:gap-4 items-center justify-center px-2">
        <NavLink
          to="home"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Home
        </NavLink>
        <NavLink
          to="services"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Services
        </NavLink>
        <NavLink
          to="products"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Products
        </NavLink>
        <NavLink
          to="projects"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Projects
        </NavLink>
        <NavLink
          to="technology"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Technology
        </NavLink>
        <NavLink
          to="pricing"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Pricing
        </NavLink>
        <NavLink
          to="members"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Members
        </NavLink>
        <NavLink
          to="about"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          About
        </NavLink>
        <NavLink
          to="contact"
          className={({ isActive }) =>
            isActive
              ? "border-b border-yellow-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
              : "hover:font-medium hover:scale-105 duration-300 cursor-pointer"
          }
        >
          Contact
        </NavLink>
        <button
          onClick={() => {
            dispatch(openSubscriptionFormModal(true));
          }}
          className="uppercase hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Subscribe
        </button>
      </div>
    </>
  );
});

export default SubMenu;
