import React, { memo } from "react";
import FooterContent from "./footer card/footer content/FooterContent";
import FooterBottom from "./footer card/footer bottom/FooterBottom";

const FooterCard = memo(() => {
  return (
    <>
      <FooterContent />
      <FooterBottom />
    </>
  );
});

export default FooterCard;
