import React, { memo } from "react";
import { MdClose } from "react-icons/md";
import { closeSubscriptionFormModal } from "../redux/slices/modalSlice";
import { useDispatch } from "react-redux";

const SubscriptionFormModal = memo(() => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="pr-4 pt-6 bg-slate-700/20 flex justify-center items-center h-screen w-screen rounded-xl fixed top-0 left-0 z-50 ">
        <div className="flex h-modalHeight w-[300px] xs:w-full 3xl:w-[1400px] rounded-xl bg-gray-700/80">
          <div className="flex flex-col w-full h-full ">
            <div className="flex justify-end p-3">
              <MdClose
                title="Close"
                className="cursor-pointer text-red-600 bg-gray-300/60 hover:bg-gray-300 rounded-full p-0.5 text-2xl"
                onClick={() => {
                  dispatch(closeSubscriptionFormModal(false));
                }}
              />
            </div>
            <div className="h-full w-full flex gap-2 items-center justify-center">
              <input
                type="email"
                name="subscribe"
                className="pl-1 h-8 w-[300px]  rounded-md outline-none text-sm placeholder:text-black bg-gray-300/80 border border-gray-300/40"
                placeholder="Enter Your Email Address"
              />
              <button className="bg-blue-600 hover:bg-blue-700 text-white px-2 py-1 rounded-md duration-300">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SubscriptionFormModal;
