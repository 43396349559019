import React, { memo } from "react";
import { Link } from "react-scroll";
import { IoIosArrowDropupCircle } from "react-icons/io";
const GoToTop = memo(() => {
  return (
    <>
      <div
        className={`fixed bottom-16 right-9 4xl:bottom-28 4xl:right-24 z-40 duration-500`}
      >
        <Link
          to="home"
          spy={true}
          smooth={true}
          offset={-80}
          duration={1500}
          activeClass="notActive"
          className="text-black text-4xl cursor-pointer animate-pulse"
        >
          <IoIosArrowDropupCircle />
        </Link>
      </div>
    </>
  );
});
export default GoToTop;
