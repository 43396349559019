import React, { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import BannerImages from "./banner swiper/BannerImages";
import BannerContent from "./banner swiper/BannerContent";
const Banner = memo(() => {
  const image = [
    // "banner1.png",
    // "banner2.png",
    // "banner3.png",
    // "banner4.png",
    "banner5.png",
    "banner6.png",
    "banner7.png",
    "banner8.png",
    "banner9.png",
    "banner10.png",
    "banner11.png",
    "banner12.png",
    // "banner13.png",
    // "banner14.png",
    // "banner15.png",
    // "banner16.png",
  ];
  return (
    <>
      <div className="border border-gray-300/40 bg-slate-100 px-2 pt-2">
        <Swiper
          centeredSlides={true}
          grabCursor={true}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="banner-swiper"
        >
          {image.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="relative">
                <BannerImages image={image} />
                <BannerContent />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
});

export default Banner;
