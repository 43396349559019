import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-scroll";
import { openSubscriptionFormModal } from "../../../redux/slices/modalSlice";
const Menu = memo(() => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="uppercase flex flex-wrap xl:flex-nowrap gap-3 lg:gap-3 2xl:gap-4 items-center justify-center px-2">
        <Link
          to="home"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Home
        </Link>
        <Link
          to="services"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Services
        </Link>
        <Link
          to="products"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Products
        </Link>
        <Link
          to="projects"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Projects
        </Link>
        <Link
          to="technology"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Technology
        </Link>
        <Link
          to="pricing"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Pricing
        </Link>
        <Link
          to="members"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Members
        </Link>
        <Link
          to="about"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          About
        </Link>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Contact
        </Link>
        {/* <Link
          to="review"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          activeClass="border-b border-yellow-500"
          className="hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Review
        </Link> */}
        <button
          onClick={() => {
            dispatch(openSubscriptionFormModal(true));
          }}
          className="uppercase hover:font-medium hover:scale-105 duration-300 cursor-pointer"
        >
          Subscribe
        </button>
      </div>
    </>
  );
});

export default Menu;
