import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import SubHeader from "../pages/SubHeader";
import SubFooter from "../pages/SubFooter";
import { useSelector } from "react-redux";
import SubscriptionFormModal from "../modal/SubscriptionFormModal";
import TeamMemberPhotoModal from "../modal/TeamMemberPhotoModal";

const Details = memo(() => {
  const memberPhotoModal = useSelector((state) => state.modal.memberPhotoModal);
  const subscriptionFormModal = useSelector(
    (state) => state.modal.subscriptionFormModal
  );
  return (
    <>
      <div className="text-sm">
        {subscriptionFormModal && <SubscriptionFormModal />}
        {memberPhotoModal && <TeamMemberPhotoModal />}
        <div className="sticky top-0 left-0 w-[300px] xs:w-full z-50">
          <SubHeader />
        </div>
        <div className="px-2 3xl:px-0 w-[300px] xs:w-full 3xl:w-[1400px] mx-auto">
          <Outlet />
        </div>
        <SubFooter />
      </div>
    </>
  );
});

export default Details;
