import React, { memo } from "react";
import ServiceBody from "../components/services/ServiceBody";

const Services = memo(() => {
  return (
    <>
      <div id="services">
        <ServiceBody />
      </div>
    </>
  );
});

export default Services;
