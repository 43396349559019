import React from "react";

function LaravelSVG({ className }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        fill="none"
        className={className}
      >
        <path d="M32.344 13.328a.43.43 0 01.013.104v5.532a.404.404 0 01-.202.35l-4.643 2.672v5.298a.404.404 0 01-.201.35l-9.69 5.578c-.023.013-.047.02-.071.03-.01.002-.018.008-.028.011a.406.406 0 01-.206 0l-.032-.014c-.022-.008-.045-.015-.066-.027l-9.69-5.578a.404.404 0 01-.202-.35V10.69c0-.036.006-.072.015-.106.003-.012.01-.023.014-.034.007-.021.014-.043.025-.063.008-.012.02-.023.028-.035.012-.016.022-.033.036-.047.012-.011.027-.02.04-.03.014-.012.028-.025.044-.035l4.845-2.79a.404.404 0 01.403 0l4.845 2.79h.001c.016.01.03.023.044.034l.04.03c.013.015.024.032.036.048.008.011.02.023.027.035.012.02.018.042.026.063.004.012.011.022.014.034.01.035.014.07.014.106v10.364l4.037-2.325v-5.299c0-.035.006-.07.015-.105.003-.011.01-.022.014-.034.008-.02.015-.043.026-.062.007-.013.018-.023.027-.036.012-.016.022-.033.036-.047.012-.011.027-.02.04-.03.015-.012.028-.025.044-.035l4.845-2.789a.403.403 0 01.404 0l4.844 2.79c.018.01.03.022.046.033l.038.03c.014.015.025.032.036.048.01.012.02.023.028.036.011.02.018.04.026.063.005.011.012.022.014.034zm-.794 5.402v-4.6l-1.695.977-2.343 1.348v4.6l4.038-2.325zm-4.845 8.32v-4.602l-2.304 1.316-6.578 3.754v4.646l8.882-5.114zM8.135 11.39V27.05l8.88 5.113V27.52l-4.64-2.626v-.001l-.003-.001c-.016-.009-.03-.022-.043-.033-.013-.01-.028-.018-.039-.03v-.001c-.014-.013-.023-.028-.034-.042-.01-.014-.022-.026-.03-.04v-.001c-.01-.015-.016-.033-.022-.05-.006-.016-.015-.03-.02-.046-.004-.02-.005-.04-.007-.06-.002-.015-.006-.03-.006-.045v-10.83l-2.342-1.35-1.695-.973v-.001zm4.44-3.022l-4.037 2.323 4.036 2.324 4.036-2.324-4.036-2.323h.001zm2.1 14.5l2.34-1.348V11.39l-1.694.975-2.342 1.349v10.13l1.695-.976zM27.108 11.11l-4.036 2.323 4.036 2.324 4.035-2.325-4.035-2.322zm-.404 5.346l-2.342-1.349-1.695-.976v4.6l2.341 1.347 1.696.977v-4.6zM17.418 26.82l5.92-3.379 2.96-1.688-4.033-2.322-4.644 2.673-4.232 2.436 4.03 2.281z"></path>
      </svg>
    </>
  );
}

export default LaravelSVG;
