import React, { memo } from "react";
import AboutBody from "../components/about/AboutBody";

const About = memo(() => {
  return (
    <>
      <div id="about">
        <AboutBody />
      </div>
    </>
  );
});

export default About;
