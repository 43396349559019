import React, { memo } from "react";
import { Outlet } from "react-router-dom";
const UserProtectedRoutes = memo(() => {
  // if (token !== null && email !== null && name !== null) {
  //   return <Navigate to={adminVerify} />;
  // }
  // if (token !== null && email !== null && name === null) {
  //   return <Navigate to={verify} />;
  // }
  // if (token === null && email === null && name === null) {
  //   return <Navigate to={login} />;
  // }
  // if (token !== null && email === null && name !== null) {
  //   return <Navigate to={admin} />;
  // }
  return <Outlet />;
});
export default UserProtectedRoutes;
