import React, { memo } from "react";
import FooterBottom from "../footer/footer card/footer bottom/FooterBottom";
import SubFooterContent from "./sub footer content/SubFooterContent";

const SubFooterCard = memo(() => {
  return (
    <>
      <SubFooterContent />
      <FooterBottom />
    </>
  );
});

export default SubFooterCard;
