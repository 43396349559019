import React, { memo } from "react";
import SubNavbar from "../components/sub header/SubNavbar";

const SubHeader = memo(() => {
  return (
    <>
      <div className="bg-cyan-300">
        <SubNavbar />
      </div>
    </>
  );
});

export default SubHeader;
