import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  memberPhotoModal: false,
  subscriptionFormModal: false,
};
const modalSlice = createSlice({
  name: "modal",
  initialState: {
    memberPhotoModal: initialState.memberPhotoModal,
    subscriptionFormModal: initialState.subscriptionFormModal,
  },
  reducers: {
    openMemberPhotoModal(state, action) {
      state.memberPhotoModal = action.payload;
    },
    closeMemberPhotoModal(state, action) {
      state.memberPhotoModal = action.payload;
    },
    openSubscriptionFormModal(state, action) {
      state.subscriptionFormModal = action.payload;
    },
    closeSubscriptionFormModal(state, action) {
      state.subscriptionFormModal = action.payload;
    },
  },
});

export const {
  openMemberPhotoModal,
  closeMemberPhotoModal,
  openSubscriptionFormModal,
  closeSubscriptionFormModal,
} = modalSlice.actions;
export default modalSlice.reducer;
