import React, { memo } from "react";
import { Link } from "react-router-dom";

const SubLogo = memo(() => {
  return (
    <>
      <Link
        to="/"
        className="flex gap-2 items-center justify-center cursor-pointer"
      >
        <img
          src="/favicon-96x96.png"
          alt="logo"
          className="h-9 w-9 rounded-full"
        />
        <div className="text-2xl">{process.env.REACT_APP_NAME}</div>
      </Link>
    </>
  );
});

export default SubLogo;
