import React, { memo } from "react";
import PriceHeader from "./price header/PriceHeader";
import PriceCard from "./price card/PriceCard";

const PricingBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <PriceHeader />
        <PriceCard />
      </div>
    </>
  );
});

export default PricingBody;
