import React, { memo } from "react";
import SubFooterCard from "../components/sub footer/SubFooterCard";

const SubFooter = memo(() => {
  return (
    <>
      <SubFooterCard />
    </>
  );
});

export default SubFooter;
