import React, { memo } from "react";

const Background = memo(() => {
  return (
    <>
      <div
        id="Background"
        className="my-3 text-justify text-base flex flex-col gap-4"
      >
        <div className="my-3 text-center text-lg font-semibold">Background</div>
        <div className="">
          The World Wide Web (WWW) was created in 1989 by the British CERN
          computer scientist Tim Berners-Lee. On 30 April 1993, CERN announced
          that the World Wide Web would be free to use for anyone, contributing
          to the immense growth of the Web. Before the introduction of the
          Hypertext Transfer Protocol (HTTP), other protocols such as File
          Transfer Protocol and the gopher protocol were used to retrieve
          individual files from a server.
        </div>
        <div className="">
          These protocols offer a simple directory structure in which the user
          navigates and where they choose files to download. Documents were most
          often presented as plain text files without formatting or were encoded
          in word processor formats.
        </div>
      </div>
    </>
  );
});

export default Background;
