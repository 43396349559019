import React, { memo } from "react";
import ProductSwiper from "./product swiper/ProductSwiper";
import ProductLogo from "./product logo/ProductLogo";

const ProductCard = memo(() => {
  const products = [
    {
      name: "OrianSoft",
      images: ["banner1.png", "banner2.png", "banner3.png", "banner4.png"],
      logo: "product-logo-1.png",
      url: "/",
    },
    {
      name: "EveryThings",
      images: ["banner1.png", "banner2.png", "banner3.png", "banner4.png"],
      logo: "product-logo-2.png",
      url: "https://everythings.oriansoft.com",
    },
    {
      name: "The News App",
      images: ["banner1.png", "banner2.png", "banner3.png", "banner4.png"],
      logo: "product-logo-3.png",
      url: "https://news.oriansoft.com",
    },
    {
      name: "HisDivineGrace",
      images: ["banner1.png", "banner2.png", "banner3.png", "banner4.png"],
      logo: "product-logo-4.png",
      url: "https://hisdivinegrace.oriansoft.com",
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-6 mt-3 px-2 bg-slate-100">
        <div className="py-3 flex gap-6 flex-col xlg:flex-row xlg:flex-wrap justify-around ">
          {products.map((product, index) => (
            <div
              key={index}
              className="flex flex-col items-center hover:scale-105 duration-300"
            >
              <div className="h-48 sm:h-52 xlg:h-40 lg:h-48 xl:h-60 w-[300px] sm:w-96 xlg:w-[280px] lg:w-[350px] xl:w-[420px] rounded-md bg-gray-700/80 ">
                <div className="flex flex-col justify-center items-center h-full w-full">
                  <ProductSwiper product={product} index={index} />
                  <ProductLogo product={product} index={index} />
                </div>
              </div>
              <div className="h-10 w-12 bg-gray-300 "></div>
              <div className="h-4 w-32 bg-gray-300 rounded-md "></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default ProductCard;
