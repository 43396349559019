import React, { memo } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
const ServiceDetails = memo(() => {
  const path = useLocation();
  const services = [
    {
      name: "Web Services",
      url: "/services/web",
    },
    {
      name: "App Services",
      url: "/services/app",
    },
    {
      name: "Rest Api Services",
      url: "/services/api",
    },
    {
      name: "Debugging Services",
      url: "/services/debugging",
    },
    {
      name: "Cloud Services",
      url: "/services/cloud",
    },
    {
      name: "Cpanel Services",
      url: "/services/cpanel",
    },

    {
      name: "Product Management Services",
      url: "/services/product-management",
    },
    {
      name: "Graphic Design Services",
      url: "/services/graphic-design",
    },
  ];
  return (
    <>
      <div className="flex justify-between w-full">
        <Outlet />
        <div className="">
          {path.pathname !== "/services" && (
            <div className="my-3 sticky top-32">
              <div className="border-l border-[#dadde1] pl-3 ">
                <div className="ml-2 mb-1 text-base font-semibold py-1 px-2">
                  More Services
                </div>
                <div className="flex flex-col flex-wrap gap-1 w-44">
                  {services.map((service, index) => (
                    <NavLink
                      key={index}
                      to={service.url}
                      className={({ isActive }) =>
                        isActive
                          ? "bg-blue-600 text-white hover:bg-gray-200/80 hover:text-black rounded-md px-2 py-0.5"
                          : "hover:bg-gray-200/80 px-2 py-1 rounded-md cursor-pointer my-0.5"
                      }
                    >
                      {service.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default ServiceDetails;
