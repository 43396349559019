import React, { memo } from "react";
import ProductsHeader from "./product header/ProductsHeader";
import ProductCard from "./product card/ProductCard";

const ProductsBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <ProductsHeader />
        <ProductCard />
      </div>
    </>
  );
});

export default ProductsBody;
