import React, { memo } from "react";
import TeamMembersBody from "../components/teams/TeamMembersBody";

const TeamMembers = memo(() => {
  return (
    <>
      <div id="members">
        <TeamMembersBody />
      </div>
    </>
  );
});

export default TeamMembers;
