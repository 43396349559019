import React, { memo } from "react";

const AboutHeader = memo(() => {
  return (
    <>
      <div className="bg-gray-300/80 py-2 text-center text-base rounded-sm">
        About Us
      </div>
    </>
  );
});

export default AboutHeader;
