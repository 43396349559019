import React, { memo } from "react";
import ServiceHeader from "./service header/ServiceHeader";
import ServiceCard from "./service card/ServiceCard";

const ServiceBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <ServiceHeader />
        <ServiceCard />
      </div>
    </>
  );
});

export default ServiceBody;
