import React, { memo } from "react";
import Banner from "../components/welcome/Banner";

const Welcome = memo(() => {
  return (
    <>
      <div className="my-2" id="home">
        <Banner />
      </div>
    </>
  );
});

export default Welcome;
