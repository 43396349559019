import React, { memo } from "react";
import ProjectHeader from "./project header/ProjectHeader";
import ProjectCard from "./project card/ProjectCard";

const ProjectsBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <ProjectHeader />
        <ProjectCard />
      </div>
    </>
  );
});

export default ProjectsBody;
