import React, { memo } from "react";
import Navbar from "../components/header/Navbar";

const Header = memo(() => {
  return (
    <>
      <div className="bg-cyan-300">
        <Navbar />
      </div>
    </>
  );
});

export default Header;
