import React, { memo } from "react";
import PhpSVG from "../../../../svg/PhpSVG";
import LaravelSVG from "../../../../svg/LaravelSVG";
import { Link } from "react-router-dom";
import ControllerOutline from "../outline/ControllerOutline";

const Controller = memo(() => {
  return (
    <>
      <div className="bg-white border h-60 w-[300px] sm:w-96 xlg:w-72 lg:w-[360px] xl:w-96 rounded-md shadow mx-auto">
        <div className="relative h-full flex flex-col justify-center items-center">
          <div className="flex items-center justify-around w-full">
            <Link
              to="https://www.w3schools.com/php/default.asp"
              target="_blank"
            >
              <PhpSVG className="h-8 mt-3 hover:scale-105 duration-300" />
            </Link>
            <div className="font-semibold leading-none mt-3">Framework</div>
            <div className="flex flex-col hover:scale-105 duration-300">
              <div className="uppercase font-semibold leading-none">
                Laravel
              </div>
              <Link to="https://laravel.com/" target="_blank">
                <LaravelSVG className="h-12 fill-[#FF2D20] ml-2" />
              </Link>
            </div>
          </div>
          <div className="mt-14 font-semibold">Controller</div>
          <ControllerOutline />
        </div>
      </div>
    </>
  );
});

export default Controller;
