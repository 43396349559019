import React, { memo, useState } from "react";
const AboutCard = memo(() => {
  const [about, setAbout] = useState(false);
  const aboutUs = {
    title: " Oriansoft: Pioneering the Future of Software Solutions Since 2022",
    bio: "In the ever-evolving landscape of technology, the year 2022 marked the inception of Oriansoft, a trailblazing software company dedicated to pushing the boundaries of innovation. Founded by a team of visionary entrepreneurs, Oriansoft set out on a mission to revolutionize the software industry by delivering cutting-edge solutions that empower businesses to thrive in the digital age.",
    description:
      "From its humble beginnings, Oriansoft quickly rose to prominence, earning a reputation for excellence and innovation. The company's commitment to delivering high-quality, user-centric software solutions has been the driving force behind its success. With a focus on leveraging the latest technologies and best practices, Oriansoft has been at the forefront of technological advancement, helping businesses across industries unlock their full potential.",
    contents:
      "One of Oriansoft's key strengths lies in its team of talented and dedicated professionals. Comprising some of the brightest minds in the industry, the Oriansoft team is passionate about creating software solutions that make a difference. With a diverse range of expertise, from software development and engineering to design and project management, the team at Oriansoft is equipped to tackle even the most complex challenges. Oriansoft's commitment to innovation is evident in its portfolio of products and services. Whether it's developing custom software solutions tailored to meet the unique needs of a business or providing consulting services to help companies optimize their existing software infrastructure, Orians  to delivering results that exceed expectations. In addition to its focus on innovation, Oriansoft is also committed to sustainability and social responsibility. The company is dedicated to reducing its environmental impact and giving back to the community through various initiatives and partnerships. As Oriansoft continues to grow and expand its reach, one thing remains constant: its dedication to delivering excellence. With a relentless pursuit of innovation and a commitment to customer satisfaction, Oriansoft is poised to lead the way in the ever-evolving world of software solutions. In conclusion, Oriansoft is not just a software company; it's a catalyst for change. With its innovative solutions, talented team, and commitment to excellence, Oriansoft is shaping the future of technology and empowering businesses to thrive in the digital age.",
  };
  return (
    <>
      <div className="bg-slate-100 mt-3  ">
        <div className="bg-white flex flex-col p-2 md:p-6 lg:py-20 justify-center items-center">
          <div className="text-center p-5 text-base font-medium">
            {aboutUs.title}
          </div>
          <div className="grid grid-cols-12 gap-3 lg:gap-10 2xl:gap-20 lg:mx-4">
            <div className="text-justify col-span-12 lg:col-span-6">
              {aboutUs.bio}
            </div>
            <div className="text-justify col-span-12 lg:col-span-6">
              <div>
                {aboutUs.description}
                <button
                  onClick={() => setAbout(true)}
                  className="ml-[3px] text-blue-600 hover:text-blue-700 hover:underline duration-300"
                >
                  {about ? "" : "Read More . . ."}
                </button>
              </div>
            </div>
          </div>
          {about && (
            <div className="mt-3 text-justify">
              {aboutUs.contents}

              <button
                onClick={() => setAbout(false)}
                className="ml-[3px] text-blue-600 hover:text-blue-700 hover:underline duration-300"
              >
                Less . . .
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default AboutCard;
