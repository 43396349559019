import React, { memo } from "react";
import Gear from "../../../svg/Gear";
import { Link } from "react-router-dom";
const ServiceCard = memo(() => {
  const service = [
    {
      logo: "service1.png",
      name: "Web",
      about:
        "A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server. Websites are typically dedicated to a particular topic or purpose, such as news, education, commerce, entertainment or social networking. Hyperlinking between web pages guides the navigation of the site, which often starts with a home page.",
    },
    {
      logo: "service2.png",
      name: "App",
      about:
        "Android software development is the process by which applications are created for devices running the Android operating system. Google states that Android apps can be written using Kotlin, Java, and C++ languages using the Android software development kit (SDK), while using other languages is also possible. All non-Java virtual machine (JVM) languages, such as Go, JavaScript, C, C++ or assembly, need the help of JVM language code, that may be supplied by tools, likely with restricted API support. Some programming languages and tools allow cross-platform app support (i.e. for both Android and iOS). Third party tools, development environments, and language support have also continued to evolve and expand since the initial SDK was released in 2008. The official Android app distribution mechanism to end users is Google Play; it also allows staged gradual app release, as well as distribution of pre-release app versions to testers.",
    },
    {
      logo: "service3.png",
      name: "Api",
      about:
        "An application programming interface (API) is a way for two or more computer programs or components to communicate with each other. It is a type of software interface, offering a service to other pieces of software. A document or standard that describes how to build or use such a connection or interface is called an API specification. A computer system that meets this standard is said to implement or expose an API. The term API may refer either to the specification or to the implementation. Whereas a system's user interface dictates how its end-users interact with the system in question, its API dictates how to write code that takes advantage of that system's capabilities.",
    },
    {
      logo: "service4.gif",
      name: "Debugging",
      about:
        "In computer programming and software development, debugging is the process of finding and resolving bugs (defects or problems that prevent correct operation) within computer programs, software, or systems. Debugging tactics can involve interactive debugging, control flow analysis, unit testing, integration testing, log file analysis, monitoring at the application or system level, memory dumps, and profiling. Many programming languages and software development tools also offer programs to aid in debugging, known as debuggers.",
    },
    {
      logo: "service5.png",
      name: "Cloud",
      about:
        "Cloud service are Internet based voice and data communications where telecommunications applications, switching and storage are hosted by a third-party outside of the organization using them, and they are accessed over the public Internet. Cloud services is a broad term, referring primarily to data-center-hosted services that are run and accessed over an Internet infrastructure. Until recently, these services have been data-centric, but with the evolution of VoIP (voice over Internet protocol), voice has become part of the cloud phenomenon. Cloud telephony (also known as hosted telephony) refers specifically to voice services and more specifically the replacement of conventional business telephone equipment, such as a private branch exchange (PBX), with third-party VoIP service.",
    },
    {
      logo: "service6.png",
      name: "Cpanel",
      about:
        "cPanel is web hosting control panel software developed by cPanel, LLC. It provides a graphical interface (GUI) and automation tools designed to simplify the process of hosting a web site to the website owner or the end user. It enables administration through a standard web browser using a three-tier structure. While cPanel is limited to managing a single hosting account, cPanel & WHM allows the administration of the entire server.",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-12 gap-3 mt-3 p-2 bg-slate-100">
        {service.map((service, index) => (
          <div
            key={index}
            className="bg-white col-span-12 xlg:col-span-6 xl:col-span-4 py-3 grid border border-gray-300/40 rounded-md shadow"
          >
            <div className="mx-auto py-6">
              {index === 0 && (
                <div className="cursor-pointer relative hover:animate-spin-forword h-20 w-20 bg-cyan-600/95 rounded-full text-white shadow shadow-gray-700/80">
                  <Gear className="fill-cyan-600/95 stroke-white h-9 absolute bottom-3 left-3 animate-spin-forword" />
                  <Gear className="fill-cyan-600/95 stroke-white h-6 absolute top-3.5 right-5 animate-spin-reverse" />
                  <Gear className="fill-cyan-600/95 stroke-white h-5 absolute bottom-4 right-3 animate-spin-reverse" />
                </div>
              )}
              {index === 1 && (
                <div className="cursor-pointer relative h-20 w-20  rounded-full shadow-md shadow-gray-700/80">
                  <div className="text-6xl ">
                    <img
                      src={`/uploads/services/${service.logo}`}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <Gear className="fill-cyan-600/95 stroke-white h-9 absolute top-2 right-5 animate-spin-forword hover:animate-spin-reverse" />
                </div>
              )}
              {index === 2 && (
                <div className="cursor-pointer relative rounded-full shadow-md shadow-gray-700/80">
                  <div className="">
                    <img
                      src={`/uploads/services/${service.logo}`}
                      alt=""
                      className="h-20 w-20 rounded-full"
                    />
                  </div>
                  <Gear className="fill-cyan-600/95 stroke-white h-7 absolute top-1.5 right-3 animate-spin-forword hover:animate-spin-reverse" />
                </div>
              )}
              {index === 3 && (
                <div className="cursor-pointer relative">
                  <div className="animate-zoom">
                    <img
                      src={`/uploads/services/debug2.png`}
                      alt=""
                      className="h-20 w-20"
                    />
                  </div>
                  <div className="absolute top-1.5 left-1 animate-pulse">
                    <img
                      src={`/uploads/services/debug1.png`}
                      alt=""
                      className="h-[50px] w-[50px] rounded-full"
                    />
                  </div>
                </div>
              )}
              {index === 4 && (
                <div className="cursor-pointer relative rounded-full shadow-md shadow-gray-700/80">
                  <div className="">
                    <img
                      src={`/uploads/services/${service.logo}`}
                      alt=""
                      className="h-20 w-20 rounded-full"
                    />
                  </div>

                  <Gear className="fill-cyan-600/95 stroke-white h-6 absolute bottom-5 right-8 animate-spin-forword hover:animate-spin-reverse" />
                  <Gear className="fill-cyan-600/95 stroke-white h-[18px] absolute top-5 right-6 animate-spin-reverse hover:animate-spin-forword" />
                </div>
              )}
              {index === 5 && (
                <div className="cursor-pointer rounded-full shadow-md shadow-gray-700/80">
                  <div className="animate-pulse">
                    <img
                      src={`/uploads/services/${service.logo}`}
                      alt=""
                      className="h-20 w-20 rounded-full"
                    />
                  </div>
                </div>
              )}
            </div>
            <Link
              to={"/services/" + service.name.toLowerCase()}
              className="ml-6 py-3 text-base cursor-pointer hover:underline"
            >
              {service.name}
            </Link>
            <div className="mx-4 py-3 text-justify cursor-pointer ">
              <div className=" text-black/70">
                {service.about.substring(0, 310) + " "}
                <Link
                  to={"/services/" + service.name.toLowerCase()}
                  className="text-blue-700/80 hover:text-blue-800 hover:underline"
                >
                  read more . . .
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
});

export default ServiceCard;
