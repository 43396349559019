import React, { memo } from "react";
import { Outlet } from "react-router-dom";

const Web = memo(() => {
  return (
    <>
      <div className="flex w-full">
        <Outlet />
      </div>
    </>
  );
});

export default Web;
