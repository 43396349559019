import React, { memo } from "react";
import { FaXTwitter } from "react-icons/fa6";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeMemberPhotoModalData } from "../../../redux/slices/dataSlice";
import { openMemberPhotoModal } from "../../../redux/slices/modalSlice";

const TeamCard = memo(() => {
  const dispatch = useDispatch();
  const members = [
    {
      name: "Comus Bala",
      image: "member-1.jpg",
      designation: "Founder & CEO",
      twitter: "https://twitter.com/",
      facebook: "https://www.facebook.com/",
      instagram: "https://www.instagram.com/",
      linkedin: "https://www.linkedin.com/",
    },
    {
      name: "Monisha Soi",
      image: "member-2.jpg",
      designation: "Managing Director",
      twitter: "https://twitter.com/",
      facebook: "https://www.facebook.com/",
      instagram: "https://www.instagram.com/",
      linkedin: "https://www.linkedin.com/",
    },
    // {
    //   name: "Sarah Jhonson",
    //   image: "member-3.jpg",
    //   designation: "Product Manager",
    //   twitter: "https://twitter.com/",
    //   facebook: "https://www.facebook.com/",
    //   instagram: "https://www.instagram.com/",
    //   linkedin: "https://www.linkedin.com/",
    // },
    // {
    //   name: "William Anderson",
    //   image: "member-4.jpg",
    //   designation: "Accountant",
    //   twitter: "https://twitter.com/",
    //   facebook: "https://www.facebook.com/",
    //   instagram: "https://www.instagram.com/",
    //   linkedin: "https://www.linkedin.com/",
    // },
  ];
  const openTeamMemberPhotoModal = (image) => {
    dispatch(openMemberPhotoModal(true));
    dispatch(storeMemberPhotoModalData(image));
  };
  return (
    <>
      <div className="mt-3 p-2 flex flex-col xlg:flex-row xlg:flex-wrap gap-3 bg-slate-100">
        {members.map((member, index) => (
          <div key={index} className="w-auto xs:w-72 lg:w-80 mx-auto">
            <div className="bg-white rounded-lg shadow shadow-black py-2">
              <div
                className="p-1 border-b-2 cursor-pointer"
                onClick={() => openTeamMemberPhotoModal(member.image)}
              >
                <img
                  src={`uploads/teams/${member.image}`}
                  className="w-auto mx-auto transition ease-in-out hover:-translate-y-1 hover:scale-90 duration-300"
                  alt={member.image}
                />
              </div>
              <div className="flex justify-center space-x-4 py-3 text-xl">
                <Link
                  to={member.twitter}
                  target="_blank"
                  className="bg-gray-300/80 p-1 transition ease-in-out hover:-translate-y-1 rounded-full border border-gray-300/40 duration-300 hover:shadow hover:shadow-black"
                >
                  <FaXTwitter />
                </Link>
                <Link
                  to={member.facebook}
                  target="_blank"
                  className="text-blue-500 bg-gray-300/80 p-1 transition ease-in-out hover:-translate-y-1 rounded-full border border-gray-300/40 duration-300 hover:shadow hover:shadow-black"
                >
                  <BsFacebook />
                </Link>
                <Link
                  to={member.instagram}
                  target="_blank"
                  className="text-red-500 bg-gray-300/80 p-1 transition ease-in-out hover:-translate-y-1 rounded-full border border-gray-300/40 duration-300 hover:shadow hover:shadow-black"
                >
                  <BsInstagram />
                </Link>
                <Link
                  to={member.linkedin}
                  target="_blank"
                  className="text-[#0077b5] bg-gray-300/80 p-1 transition ease-in-out hover:-translate-y-1 rounded-full border border-gray-300/40 duration-300 hover:shadow hover:shadow-black"
                >
                  <BsLinkedin />
                </Link>
              </div>
              <div className="text-center py-3 bg-gray-100 cursor-pointer">
                <div className="no-underline text-black hover:underline">
                  <Link
                    to={`member/${member.name.toLowerCase()}`}
                    className="text-base font-medium hover:scale-105 duration-300"
                  >
                    {member.name}
                  </Link>
                </div>
                <Link to={`member/${member.name.toLowerCase()}`}>
                  {member.designation}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
});

export default TeamCard;
