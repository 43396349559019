import React from "react";
import RightArrow from "../../../../svg/RightArrow";

function ControllerOutline() {
  return (
    <>
      <div className="absolute  top-[83px] left-20 sm:left-24 xlg:left-20 lg:left-[90px] xl:left-24 border-t-8 border-t-gray-300 w-6 sm:w-12 xlg:w-4 lg:w-11 xl:w-12"></div>
      <div className="absolute top-[75px] left-[103px] sm:left-[141px] xlg:left-24 lg:left-32 xl:left-[141px]">
        <RightArrow className="fill-gray-300 h-6" />
      </div>
      <div className="absolute top-[83px] right-[75px] sm:right-[90px] xlg:right-[70px] lg:right-20 xl:right-[90px] border-t-8 border-t-gray-300 w-9 xl:w-[60px] xlg:w-8 lg:w-[55px] sm:w-[60px]"></div>
      <div className="absolute top-[75px] right-16 sm:right-20 xlg:right-16 lg:right-[75px] xl:right-20">
        <RightArrow className="fill-gray-300 h-6" />
      </div>
    </>
  );
}

export default ControllerOutline;
