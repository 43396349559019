import React, { memo } from "react";

const DynamicWebsite = memo(() => {
  const dynamic = "dynamic.png";
  return (
    <>
      <div
        id="Dynamic"
        className="my-3 text-justify text-base flex flex-col gap-4"
      >
        <div className="my-3 text-center text-lg font-semibold">
          Dynamic website
        </div>

        <div className="">
          A dynamic website is one that changes or customizes itself frequently
          and automatically. Server-side dynamic pages are generated "on the
          fly" by computer code that produces the HTML (CSS are responsible for
          appearance and thus, are static files). There are a wide range of
          software systems, such as CGI, Java Servlets and Java Server Pages
          (JSP), Active Server Pages and ColdFusion (CFML) that are available to
          generate dynamic Web systems and dynamic sites.
        </div>

        <div className="">
          Various Web application frameworks and Web template systems are
          available for general-use programming languages like Perl, PHP, Python
          and Ruby to make it faster and easier to create complex dynamic
          websites.
        </div>
        <div className="flex flex-col items-center justify-center w-full  gap-2">
          <img
            src={`/uploads/others/dynamic/${dynamic}`}
            alt={dynamic}
            className="w-96 rounded-md shadow"
          />
          <div className="">Server-side programming language usage in 2016</div>
        </div>
        <div className="">
          A site can display the current state of a dialogue between users,
          monitor a changing situation, or provide information in some way
          personalized to the requirements of the individual user. For example,
          when the front page of a news site is requested, the code running on
          the webserver might combine stored HTML fragments with news stories
          retrieved from a database or another website via RSS to produce a page
          that includes the latest information.
        </div>
        <div className="">
          Dynamic sites can be interactive by using HTML forms, storing and
          reading back browser cookies, or by creating a series of pages that
          reflect the previous history of clicks. Another example of dynamic
          content is when a retail website with a database of media products
          allows a user to input a search request, e.g. for the keyword Beatles.
          In response, the content of the Web page will spontaneously change the
          way it looked before, and will then display a list of Beatles products
          like CDs, DVDs, and books.
        </div>
        <div className="">
          Dynamic HTML uses JavaScript code to instruct the Web browser how to
          interactively modify the page contents. One way to simulate a certain
          type of dynamic website while avoiding the performance loss of
          initiating the dynamic engine on a per-user or per-connection basis is
          to periodically automatically regenerate a large series of static
          pages.
        </div>
      </div>
    </>
  );
});

export default DynamicWebsite;
