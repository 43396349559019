import React, { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
const ProductSwiper = memo(({ product, index }) => {
  return (
    <>
      <Swiper
        centeredSlides={true}
        grabCursor={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="product-swiper"
      >
        {product.images.map((image, i) => (
          <SwiperSlide key={i}>
            <img
              src={`uploads/products/banners/${index}/${image}`}
              alt={image}
              className="p-2 pb-0 h-full w-full"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
});

export default ProductSwiper;
