import { configureStore } from "@reduxjs/toolkit";
import toggleSlice from "./slices/toggleSlice";
import modalSlice from "./slices/modalSlice";
import dataSlice from "./slices/dataSlice";

export const store = configureStore({
  reducer: {
    toggle: toggleSlice,
    modal: modalSlice,
    data: dataSlice,
  },
});
