import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeMemberPhotoModal } from "../redux/slices/modalSlice";
import { MdClose } from "react-icons/md";
const TeamMemberPhotoModal = memo(() => {
  const memberPhoto = useSelector((state) => state.data.memberPhotoModalData);
  const dispatch = useDispatch();
  const closeTeamMemberPhotoModal = () => {
    dispatch(closeMemberPhotoModal(false));
  };
  return (
    <>
      <div className="pr-4 pt-6 bg-slate-700/20 flex justify-center items-center h-screen w-screen rounded-xl fixed top-0 left-0 z-50 ">
        <div className="flex h-modalHeight w-[300px] xs:w-full 3xl:w-[1400px] rounded-xl bg-gray-700/80">
          <div className="flex flex-col w-full h-full ">
            <div className="flex justify-end p-3">
              <MdClose
                title="Close"
                className="cursor-pointer text-red-600 bg-gray-300/60 hover:bg-gray-300 rounded-full p-0.5 text-2xl"
                onClick={() => {
                  closeTeamMemberPhotoModal(false);
                }}
              />
            </div>
            <div className="h-full w-full flex items-center justify-center">
              <img
                onClick={() => {
                  closeTeamMemberPhotoModal(false);
                }}
                src={`uploads/teams/${memberPhoto}`}
                className="h-[500px] w-[500px] rounded-md transition ease-in-out hover:-translate-y-1 hover:scale-150 duration-1000 cursor-pointer"
                alt={memberPhoto}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default TeamMemberPhotoModal;
