import React, { memo, useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import DataTable, { createTheme } from "react-data-table-component";
import useTableHeaderStyle from "./../../../../../../hooks/useTableHeaderStyle";
import useTableTheme from "./../../../../../../hooks/useTableTheme";
import Spining from "./../../../../../../loading/Spining";

const ReactTableOfContent = memo(({ initialState }) => {
  const [style] = useTableHeaderStyle();
  const [theme] = useTableTheme();
  const [loading, setLoading] = useState(initialState);
  //   const [search, setSearch] = useState("");
  const [columns, setColumns] = useState([]);
  const data = [
    {
      types: "Affiliate network",
      description:
        "A site, typically few in pages, whose purpose is to sell a third party's product. The seller receives a commission for facilitating the sale.",
      examples: "",
    },
    {
      types: "Affiliate agency",
      description:
        "Enabled portal that renders not only its custom CMS but also syndicated content from other content providers for an agreed fee. There are usually three relationship tiers (see Affiliate Agencies).",
      examples:
        "Commission Junction, advertisers like eBay, or a consumer like Yahoo!.",
    },
    {
      types: "Archive site",
      description:
        "Used to preserve valuable electronic content threatened with extinction. Two examples are: Internet Archive, which since 1996 has preserved billions of old (and new) web pages; and Google Groups, which in early 2005 was archiving over 845,000,000 messages posted to Usenet news/discussion groups.",
      examples: "Internet Archive, Google Groups",
    },
    {
      types: "Malware website",
      description:
        "A site created specifically to attack visitors' computers on their first visit to a website by downloading a file (usually a trojan horse). These websites rely on unsuspecting users with poor anti-virus protection in their computers.",
      examples: "",
    },
    {
      types: "Membership website",
      description:
        "a general term for a closed community that operates on the web using a monthly membership fee system. It is run by individuals or multiple members who possess specialized knowledge or have excellent skills and experience in a particular field, such as writers, entrepreneurs, athletes, bloggers, etc. In addition, there are examples of it being utilized as a fan club for artists, idols, models, TV personalities, and athletes. Since the 2010s, there have been companies that support individuals and corporations who own membership websites as a business or hobby.",
      examples: "",
    },
    {
      types: "Blog (weblog)",
      description:
        "Sites generally used to post online diaries which may include discussion forums. Many bloggers use blogs like an editorial section of a newspaper to express their ideas on anything ranging from politics to religion to video games to parenting, along with anything in between. Some bloggers are professional bloggers and they are paid to blog about a certain subject, and they are usually found on news sites.",
      examples: "WordPress",
    },
    {
      types: "Brand-building site",
      description:
        "A site with the purpose of creating an experience of a brand online. These sites usually do not sell anything, but focus on building the brand. Brand building sites are most common for low-value, high-volume fast-moving consumer goods (FMCG).",
      examples: "",
    },
    {
      types: "Celebrity website",
      description:
        "A website the information in which revolves around a celebrity or public figure. These sites can be official (endorsed by the celebrity) or fan-made (run by a fan or fans of the celebrity without implicit endorsement).",
      examples: "jimcarrey.com",
    },
    {
      types: "Comparison shopping website",
      description:
        "A website providing a vertical search engine that shoppers use to filter and compare products based on price, features, reviews, and other criteria.",
      examples: "Shopping.com",
    },
    {
      types: "Crowdfunding website",
      description:
        "Platform to fund projects by the pre-purchase of products or by asking audience members to make a donation.",
      examples: "Kickstarter",
    },
    {
      types: "Click-to-donate site",
      description:
        "A website that allows the visitor to donate to charity simply by clicking on a button or answering a question correctly. An advertiser usually donates to the charity for each correct answer generated.",
      examples: "The Hunger Site, Freerice",
    },
    {
      types: "Content site",
      description:
        "A site the business of which is the creation and distribution of original content",
      examples: "wikiHow.com, About.com",
    },
    {
      types: "Classified ads site",
      description: "A site publishing classified advertisements.",
      examples: "gumtree.com, Craigslist",
    },
    {
      types: "Corporate website",
      description:
        "A site where users can find other single people looking for long-term relationships, dating, short encounters, or friendship. Many of them are pay per services, but there are many free or partially free dating sites. Most dating sites in the 2010s have the functionality of social networking websites.",
      examples: "eHarmony, Match.com",
    },
    {
      types: "E-commerce website",
      description:
        "A site offering goods and services for online sale and enabling online transactions for such sales.",
      examples: "Amazon.com",
    },
    {
      types: "Fake news website",
      description:
        "A site publishing fake news stories, intending to deceive visitors, and profit from advertising.",
      examples: "The Daily Stormer",
    },
    {
      types: "Internet forum",
      description:
        "A site where people can hold conversations in the form of posted messages.",
      examples: "SkyscraperCity, 4chan",
    },
    {
      types: "Gallery website",
      description:
        "A website designed specifically for use as a gallery; these may be an art gallery or photo gallery and of commercial or non-commercial nature.",
      examples: "",
    },
    {
      types: "Government website",
      description:
        "A website made by the local, state, department, or national government of a country. Usually, these sites also operate websites that are intended to inform tourists or support tourism.",
      examples: "USA.gov, Naenara, GOV.UK",
    },
    {
      types: "Gripe site",
      description:
        "A site devoted to the criticism of a person, place, corporation, government, or institution.",
      examples: "",
    },
    {
      types: "Online game website",
      description: "Websites where users can play online games",
      examples: "Browser games, OGame, Travian",
    },
    {
      types: "Online gambling website",
      description: "A site that lets users play online games such as gambling.",
      examples: "",
    },
    {
      types: "Humor site",
      description: "Satirizes, parodies, or amuses the audience.",
      examples:
        "The Onion, National Lampoon digital archive, Encyclopedia Dramatica",
    },
    {
      types: "Information site",
      description:
        "Most websites fit in this category to some extent. They do not necessarily have commercial purposes.",
      examples:
        "Most government, educational and nonprofit institutions have an informational site.",
    },
    {
      types: "Media-sharing site",
      description:
        "A site that enables users to upload and view media such as pictures, music, and videos",
      examples: "YouTube, DeviantArt",
    },
    {
      types: "Mirror site",
      description:
        "A website that is the replication of another website. This type of website is used as a response to spikes in user visitors. Mirror sites are most commonly used to provide multiple sources of the same information and are of particular value as a way of providing reliable access to large downloads.",
      examples: "",
    },
    {
      types: "Microblog website",
      description:
        "A short and simple form of blogging. Microblogs are limited to certain numbers of characters and work similarly to a status update on Facebook.",
      examples: "Twitter",
    },
    {
      types: "News site",
      description:
        "Similar to an information site, but dedicated to dispensing news, politics, and commentary.",
      examples: "cnn.com, bbc.com/news",
    },
    {
      types: "Personal website",
      description:
        "Websites about an individual or a small group (such as a family) that contains information or any content that the individual wishes to include. Such a personal website is different from a celebrity website, which can be very expensive and run by a publicist or agency.",
      examples: "",
    },
    {
      types: "Photo sharing website",
      description:
        "A website created to share digital photos with the online community. (see Photo sharing).",
      examples: "Flickr, Instagram, Imgur",
    },
    {
      types: "p2p/Torrents website",
      description:
        "Websites that index torrent files. This type of website is different from a BitTorrent client which is usually a stand-alone software.",
      examples: "Mininova, The Pirate Bay, IsoHunt",
    },
    {
      types: "Political site",
      description:
        "A site on which people may voice political views, provide political humor, campaign for elections, or provide information about a certain candidate, political party or ideology.",
      examples: "Rhino Party of Canada website",
    },
    {
      types: "Question and Answer (Q&A) site",
      description: "A site where people can ask questions and get answers.",
      examples:
        "Quora, Yahoo! Answers, Stack Exchange Network (including Stack Overflow)",
    },
    {
      types: "Religious site",
      description:
        "A site in which people may advertise a place of worship, or provide inspiration or seek to encourage the faith of a follower of that religion.",
      examples: "",
    },
    {
      types: "Review site",
      description:
        "A site on which people can post reviews for products or services.",
      examples: "Yelp, Rotten Tomatoes",
    },
    {
      types: "School website",
      description:
        "A site on which teachers, students, or administrators can post information about current events at or involving their school. U.S. elementary-high school websites generally use k12 in the URL",
      examples: "",
    },
    {
      types: "Scraper site",
      description:
        "A site that largely duplicates the content of another site without permission, without actually pretending to be that site, in order to capture some of that site's traffic (especially from search engines) and profit from advertising revenue or in other ways.",
      examples: "",
    },
    {
      types: "Search engine",
      description:
        "A website that indexes material on the Internet or an intranet (and lately on traditional media such as books and newspapers) and provides links to information as a response to a query.",
      examples: "Google Search, Bing, DuckDuckGo, Ecosia",
    },
    {
      types: "Shock site",
      description:
        "Includes images or other material that is intended to be offensive to most viewers.",
      examples: "Goatse.cx, rotten.com",
    },
    {
      types: "Showcase website",
      description:
        "Web portals used by individuals and organisations to showcase things of interest or value.",
      examples: "",
    },
    {
      types: "Social bookmarking site",
      description:
        "A site where users share other content from the Internet and rate and comment on the content.",
      examples: "StumbleUpon, Digg",
    },
    {
      types: "Social networking service",
      description:
        "A site where users could communicate with one another and share media, such as pictures, videos, music, blogs, etc. with other users. These may include games and web applications.",
      examples: "YouTube, Facebook, Instagram, Pinterest, LinkedIn",
    },
    {
      types: "Social news",
      description:
        "A social news website features user-posted stories that are ranked based on popularity. Users can comment on these posts, and these comments may also be ranked. Since their emergence with the birth of web 2.0, these sites are used to link many types of information including news, humor, support, and discussion. Social news sites allegedly facilitate democratic participation on the web.",
      examples: "Reddit, Digg, SlashDot",
    },
    {
      types: "Warez",
      description:
        "A site designed to host or link to materials such as music, movies, and software for the user to download.",
      examples: "The Pirate Bay",
    },
    {
      types: "Webcomic",
      description:
        "An online comic, ranging in various styles and genres unique to the World Wide Web.",
      examples: "Penny Arcade, xkcd, Gunnerkrigg Court",
    },
    {
      types: "Webmail",
      description: "A site that provides a webmail service.",
      examples: "Hotmail, Gmail, Protonmail, Yahoo! Mail",
    },
    {
      types: "Web portal",
      description:
        "A site that provides a starting point or a gateway to other resources on the Internet or an intranet.",
      examples: "msn.com, msnbc.com, Newgrounds, Yahoo!",
    },
    {
      types: "Wiki site",
      description: "A site in which users collaboratively edit its content.",
      examples: "Wikipedia, wikiHow, Fandom",
    },
  ];
  // useEffect(() => {
  //   const result = data.filter((item) => {
  //     return item.types.toLowerCase().match(search.toLowerCase());
  //   });
  //   setData(result);
  // }, [search, data, setData]);
  useEffect(() => {
    setColumns([
      {
        name: "Types of Website",
        cell: (row) => row.types,
        sortable: true,
        width: "160px",
      },
      {
        name: "Description",
        cell: (row) => row.description,
      },
      {
        name: "Examples",
        cell: (row) => row.examples,
      },
    ]);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  createTheme("table", theme, "dark");

  return (
    <>
      <DataTable
        title={<div className="text-base">Examples of Web Services </div>}
        customStyles={style}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        progressComponent={
          <div className="h-40 flex items-center justify-center w-full">
            <Spining />
          </div>
        }
        fixedHeader
        responsive
        selectableRowsHighlight
        highlightOnHover
        actions={
          <button
            title="Export as PDF"
            className="text-black bg-gray-100 p-1 rounded-md text-lg mr-6"
          >
            <FiLogOut />
          </button>
        }
        // subHeader
        // subHeaderComponent={
        //   <input
        //     type="text"
        //     className="mr-4  border placeholder:text-sm border-gray-300/40 pl-1 h-8 rounded-md focus:outline-none bg-gray-100/80 w-80"
        //     placeholder="Search . . ."
        //     value={search}
        //     onChange={(e) => setSearch(e.target.value)}
        //   />
        // }
        subHeaderAlign="right"
        theme="table"
      />
    </>
  );
});

export default ReactTableOfContent;
