function useTableTheme() {
  const theme = {
    background: {
      default: "rgb(225 225 225 / 0.8 )",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
    highlightOnHover: {
      default: "#ffffff",
      text: "#000000",
    },
    divider: {
      default: "#dadde1",
    },
  };
  return [theme];
}

export default useTableTheme;
