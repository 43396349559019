import React, { memo } from "react";
import TechnologyBody from "../components/technology/TechnologyBody";

const Technology = memo(() => {
  return (
    <>
      <div id="technology">
        <TechnologyBody />
      </div>
    </>
  );
});

export default Technology;
