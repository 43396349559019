import React, { memo } from "react";
import MySqlSVG from "./../../../../svg/MySqlSVG";
import DatabaseSVG from "./../../../../svg/DatabaseSVG";
import { Link } from "react-router-dom";
import ModelOutline from "../outline/ModelOutline";

const Model = memo(() => {
  return (
    <>
      <div className="bg-white border h-60 w-[300px] sm:w-96 xlg:w-72  lg:w-[360px] xl:w-96 rounded-md shadow mx-auto">
        <div className="relative h-full flex flex-col justify-center items-center">
          <div className="flex items-center justify-around w-full">
            <Link
              to="https://www.w3schools.com/mysql/default.asp"
              target="_blank"
            >
              <MySqlSVG className="h-12 hover:scale-105 duration-300" />
            </Link>
            <div className="font-semibold leading-none mt-3">Qurey?</div>
            <div className="flex flex-col hover:scale-105 duration-300">
              <div className="uppercase font-semibold leading-none mb-1">
                Database
              </div>
              <Link
                to="https://www.w3schools.com/sql/sql_ref_database.asp"
                target="_blank"
              >
                <DatabaseSVG className="h-10 fill-red-700 ml-[18px]" />
              </Link>
            </div>
          </div>
          <div className="mt-14 font-semibold">Model</div>
          <ModelOutline />
        </div>
      </div>
    </>
  );
});

export default Model;
