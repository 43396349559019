import React, { memo } from "react";
const Static = memo(() => {
  const profile = "profile.jpg";
  const image1 = "banner1.png";
  const image2 = "banner2.png";
  const image3 = "banner3.png";
  const image4 = "banner4.png";
  return (
    <>
      <div className="my-3 bg-white py-10">
        <div className="h-[520px] w-[1000px] bg-gray-300 mx-auto rounded-md ring-4 ring-gray-600">
          <div className="pt-10 grid grid-cols-12 items-center justify-center gap-6 p-6">
            <div className="col-span-2"></div>
            <div className="col-span-2 flex justify-end">
              <img
                src={`/uploads/profile/${profile}`}
                alt={profile}
                className="h-36 w-36 rounded-full ring-2 ring-yellow-400"
              />
            </div>
            <div className="col-span-6">
              <div className="text-5xl text-blue-900 mb-1">Comus Bala</div>
              <div className="text-2xl text-blue-600">
                Full Stack Web Developer
              </div>
            </div>
          </div>
          <div className="text-3xl text-center">
            Offers a wide range of responsive, custom website.
          </div>
          <div className="mt-3 w-full flex justify-around">
            <div className="ml-20 flex flex-col">
              <div className="font-bold text-lg bg-blue-600 px-2 py-1 text-white rounded-md">
                Skills:
              </div>
              <div className="flex gap-6">
                <div className=" flex flex-col gap-1 mt-2 font-semibold">
                  <div>HTML</div>
                  <div>CSS</div>
                  <div>JavaScripts</div>
                  <div>PHP</div>
                  <div>MySql</div>
                  <div>React</div>
                </div>
                <div className="ml-10 flex flex-col gap-1 mt-2 font-semibold">
                  <div>Laravel</div>
                  <div>Redux</div>
                  <div>Jqurey</div>
                  <div>Ajax</div>
                  <div>Docker</div>
                  <div>Github</div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="flex flex-col items-center">
                <div className="h-48 w-80 rounded-md bg-gray-700/80 ">
                  <div className="flex gap-2 pt-2 px-3">
                    <img
                      src={`/uploads/products/banners/0/${image1}`}
                      alt={image1}
                      className="h-20 w-36  rounded-md shadow shadow-white"
                    />
                    <img
                      src={`/uploads/products/banners/1/${image2}`}
                      alt={image2}
                      className=" h-20 w-36  rounded-md shadow shadow-white"
                    />
                  </div>
                  <div className="flex gap-2 pt-2 px-3">
                    <img
                      src={`/uploads/products/banners/2/${image3}`}
                      alt={image3}
                      className=" h-20 w-36  rounded-md shadow shadow-white"
                    />
                    <img
                      src={`/uploads/products/banners/3/${image4}`}
                      alt={image4}
                      className=" h-20 w-36  rounded-md shadow shadow-white"
                    />
                  </div>
                </div>
                <div className="h-10 w-12 bg-gray-700/80 "></div>
                <div className="h-4 w-32 bg-gray-700/80 rounded-md "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Static;
