import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  menu: false,
};
const toggleSlice = createSlice({
  name: "toggle",
  initialState: {
    menu: initialState.menu,
  },
  reducers: {
    toggleMenu(state, action) {
      state.menu = action.payload;
    },
  },
});

export const { toggleMenu } = toggleSlice.actions;
export default toggleSlice.reducer;
