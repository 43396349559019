import React, { memo } from "react";

const Overview = memo(() => {
  return (
    <>
      <div className="" id="Overview">
        <div className="my-3 text-center text-lg font-semibold">Overview</div>
        <div className="text-justify text-base flex flex-col gap-4">
          <div className="">
            A website is a collection of web pages and related content that is
            identified by a common domain name and published on at least one web
            server.
          </div>
          <div className="">
            Websites are typically dedicated to a particular topic or purpose,
            such as news, education, commerce, entertainment or social
            networking. Hyperlinking between web pages guides the navigation of
            the site, which often starts with a home page. All publicly
            accessible websites collectively constitute the World Wide Web.
          </div>
          <div className="">
            There are also private websites that can only be accessed on a
            private network, such as a company's internal website for its
            employees.
          </div>
          <div className="">
            Users can access websites on a range of devices, including desktops,
            laptops, tablets, and smartphones. The app used on these devices is
            called a web browser.
          </div>
        </div>
      </div>
    </>
  );
});

export default Overview;
