import React, { memo } from "react";
import ProductBody from "../components/products/ProductBody";

const Products = memo(() => {
  return (
    <>
      <div id="products">
        <ProductBody />
      </div>
    </>
  );
});

export default Products;
