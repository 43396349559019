import React, { memo } from "react";
import ContactHeader from "./contact header/ContactHeader";
import ContactCard from "./contact card/ContactCard";

const ContactBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <ContactHeader />
        <ContactCard />
      </div>
    </>
  );
});

export default ContactBody;
