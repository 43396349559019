import React, { memo } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
const ContactCard = memo(() => {
  return (
    <>
      <div className="bg-slate-100  py-3">
        <div className="lg:mx-10">
          <div className="py-2">
            <iframe
              title="map"
              style={{ border: `0`, width: `100%`, height: `270px` }}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3507.12503195417!2d77.508943!3d28.475781000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cebc346fc1eb7%3A0xc1a72f82cf43bc9a!2sB129%2CBlock-B%2CAlfa-1!5e0!3m2!1sen!2sin!4v1707737134703!5m2!1sen!2sin"
              allowFullScreen
            ></iframe>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-2 mt-5">
            <div className="py-3 md:col-span-1 lg:col-span-1">
              <div className="flex space-x-2 align-baseline bg">
                <div className="text-red-500 hover:text-yellow-500 mt-1">
                  <HiOutlineLocationMarker />
                </div>
                <div className="">
                  <h4>Location:</h4>
                  <p>
                    H-129, Block B, Alpha I, Greater Noida, Uttar Pradesh -
                    201310
                  </p>
                </div>
              </div>

              <div className="flex space-x-2 align-top bg">
                <div className="text-blue-500 hover:text-amber-900 mt-1 ">
                  <AiOutlineMail className="" />
                </div>
                <div className="">
                  <h4>Email:</h4>
                  <p>info@oriansoft.com</p>
                </div>
              </div>

              <div className="flex space-x-2 align-baseline bg">
                <div className=" text-pink-500 hover:text-green-500 mt-1">
                  <FaMobileAlt />
                </div>
                <div className="">
                  <h4>Call:</h4>
                  <p>+919832925083</p>
                </div>
              </div>
            </div>

            <div className=" p-3 bg-gray-300 space-y-2 rounded-md md:col-span-2 lg:col-span-1">
              <div className="grid grid-rows-2">
                <label htmlFor="Name" className="pb-2 font-bold">
                  Your Name: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  className="pl-1 rounded-md outline-none"
                  placeholder=" Enter Your Name"
                />
              </div>
              <div className="grid grid-rows-2">
                <label htmlFor="Email" className="pb-2 font-bold">
                  Your Email: <span className="text-red-600">*</span>
                </label>
                <input
                  type="email"
                  name=""
                  className="pl-1 rounded-md outline-none"
                  placeholder=" Enter Your Email Address"
                />
              </div>
              <div className="grid grid-rows-2">
                <label htmlFor="Number" className="pb-2 font-bold">
                  Phone: <span className="text-red-600">*</span>
                </label>
                <input
                  type="phone"
                  name=""
                  className="pl-1 rounded-md outline-none"
                  placeholder=" Enter Your Phone Number"
                />
              </div>
              <div className="grid grid-rows-2">
                <label htmlFor="Message" className="font-bold">
                  Message: <span className="text-red-600">*</span>
                </label>
                <textarea
                  name="message"
                  className="pl-1 rounded-md outline-none"
                  placeholder=" Write Your Message"
                ></textarea>
              </div>
              <div className="text-center p-1">
                <button className="p-1 mr-2 px-2 bg-blue-500 hover:bg-blue-600 duration-300 text-white rounded-md">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default ContactCard;
