import React, { memo } from "react";
import { Link } from "react-router-dom";

const SubMenu1 = memo(() => {
  return (
    <>
      <Link
        to="home"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Home
      </Link>
      <Link
        to="services"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Services
      </Link>
      <Link
        to="products"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Products
      </Link>
      <Link
        to="projects"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Projects
      </Link>
      <Link
        to="technology"
        className="text-blue-500 hover:font-medium hover:scale-105 duration-300 cursor-pointer"
      >
        Technology
      </Link>
    </>
  );
});

export default SubMenu1;
