import React, { memo } from "react";
import ReactTableOfContent from "./table of content/ReactTableOfContent";

const TableOfContent = memo(({ initialState }) => {
  return (
    <>
      <div
        id="Table"
        className="my-3 text-justify text-base flex flex-col gap-4"
      >
        <div className="my-3 text-center text-lg font-semibold">
          Table of Content
        </div>
        <div className="">
          <ReactTableOfContent initialState={initialState} />
        </div>
      </div>
    </>
  );
});

export default TableOfContent;
