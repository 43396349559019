import React, { memo } from "react";
import { Link } from "react-router-dom";

const SubFooterServices = memo(() => {
  return (
    <>
      <Link
        to="/services/web"
        className="text-blue-500 hover:scale-105 duration-300"
      >
        Web Design & Development
      </Link>

      <Link
        to="/services/app"
        className="text-blue-500 hover:scale-105 duration-300"
      >
        App Design & Development
      </Link>
      <Link
        to="/services/product-management"
        className="text-blue-500 hover:scale-105 duration-300"
      >
        Product Management
      </Link>
      <Link
        to="/services/graphic-design"
        className="text-blue-500 hover:scale-105 duration-300"
      >
        Graphic Design
      </Link>
    </>
  );
});

export default SubFooterServices;
