import React, { memo } from "react";

const BannerImages = memo(({ image }) => {
  return (
    <>
      <div className="h-[150px] xs:h-[150px] sm:h-[180px] md:h-[220px] xlg:h-[280px] lg:h-[360px] xl:h-[480px] 2xl:h-[560px] 3xl:h-[668px]">
        <img
          src={`/uploads/banners/${image}`}
          alt={image}
          className="h-full w-full"
        />
        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
      </div>
    </>
  );
});

export default BannerImages;
