import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { ImCheckmark } from "react-icons/im";
const PriceCard = memo(() => {
  const [features, setFeatures] = useState(false);
  const [featuresIndex, setFeaturesIndex] = useState(null);
  const handleShowFeatures = (index, e) => {
    e.preventDefault();
    setFeaturesIndex(index);
    setFeatures(true);
  };
  const handleHideFeatures = (index, e) => {
    e.preventDefault();
    setFeaturesIndex(index);
    setFeatures(false);
  };

  const websites = [
    {
      id: 1,
      name: "Static",
      url: "/pricing/static",
      features: [
        {
          name: "Strong Security",
          description:
            "Static websites are generally considered more secure than dynamic websites because they don't require a server side scripting language.",
        },
        {
          name: "Speed",
          description:
            "Static websites are pre-built and their content is served “as-is” to the web browser, which means that web pages load quickly.",
        },
        {
          name: "Low Cost",
          description:
            "As there is no need for a content management system (CMS) or complex server-side programming, you can save a significant amount of development costs.",
        },
        {
          name: "Scalable",
          description:
            "Scalability is the property of a system to handle a growing amount of work. One definition for software systems specifies that this may be done by adding resources to the system. In an economic context, a scalable business model implies that a company can increase sales given increased resources.",
        },
        {
          name: "functionality",
          description:
            "Static websites are limited in terms of functionality and cannot offer features like user accounts, dynamic content, or ecommerce functionality.",
        },
        {
          name: "Simple to Define",
          description:
            "Static websites display exactly the same information whenever anyone visits it. User sees the updated content of Static Website only when a web author manually update the content.",
        },

        {
          name: "Easy to Create",
          description:
            "Static websites are easy to create and maintain, even for beginners with minimal technical knowledge. Static websites are straightforward and easy to navigate due to their clear structure and layout.",
        },
        {
          name: "Reliability",
          description:
            "Static websites are more reliable than dynamic websites because they don't rely on third-party services or external APIs to function properly.",
        },
        {
          name: "SEO Friendly",
          description:
            "Much-better for SEO. When you're optimizing your site for search traffic, it's usually best to steer clear of dynamic websites.",
        },
      ],
    },
    {
      id: 2,
      name: "Dynamic",
      url: "/pricing/dynamic",
      features: [
        {
          name: "Language Settings",
          description:
            "Adjust Language Settings. More and more dynamic websites can display content in multiple languages, based on where their visitors are located.",
        },
        {
          name: "Dynamic Visuals and Animations",
          description:
            "Besides quality content, visuals are crucial for a successful website. High-quality images, dynamic visuals, and animations help keep visitors engaged, preventing them from leaving your site immediately.Animations like slide shows and pop-ups are also useful for organizing your content. To enable animations on your dynamic website, you have to use a client-side scripting language.",
        },
        {
          name: "Page speed",
          description:
            "The information contained in a dynamic web page leads to more business in that it creates a better customer experience with content that's tailored to user.",
        },
        {
          name: "Product Recommendation",
          description:
            "Handle duplicate content with automatic 301's and canonicals. Product Recommendation. Automatic gathering of visitor data; Use models based on related products.",
        },
        {
          name: "Provides custom recommendations",
          description:
            "Based on Past Searches Dynamic websites let you track visitors’ session information and use it to display content based on their preferences. For instance, you can recommend products similar to the ones they have previously searched for.",
        },
        {
          name: "Rich ux",
          description:
            "Dynamic Website Designing. Customizable Interface; Unparalleled Flexibility; Full Website Control; Rich UX",
        },
        {
          name: "Easy Updating",
          description:
            "One of the best benefits of choosing a dynamic website design is that it allows you to update content easily based on your requirements. ",
        },
        {
          name: "Search engine optimization",
          description:
            "Dynamic websites can be more challenging to optimize for search engines because the content is generated on the fly. This can make it more difficult for search engines to crawl and index the site, which can result in lower rankings and less visibility. ",
        },
        {
          name: "Social media integration",
          description:
            "Integration of social media and offsite content. One way to add new and changing content on a site is by tapping into social media.",
        },
        {
          name: "Content management system",
          description:
            "A content management system is computer software used to manage the creation and modification of digital content. A CMS is typically used for enterprise content management and web content management.",
        },
        {
          name: "Easily Manageable",
          description:
            " Dynamic sites can be built with an admin panel, and like PHP, MVC languages are used by them to interact with information. ",
        },

        {
          name: "Provide more functionality",
          description:
            "They provide more website functionality and enable user interaction. Dynamic sites let you request and store information in an organized way.",
        },
        {
          name: "Language based on country",
          description:
            "Dynamic websites can change their content language automatically based on visitors’ location. To do this, use API integration or write a server-side script to read the Accept-Language HTTP request header. Application programming interfaces (APIs) let your site detect visitors’ location based on their IP addresses or GPS. Some of them also detect users’ web browsers’ language settings and adjust the site content accordingly.",
        },
        {
          name: "Personalization",
          description:
            "Depending on their location and browsing history, such websites can be personalized for users.",
        },
        {
          name: "Preview in different screen resolutions",
          description:
            "Dynamic website development allows the page to display on almost any screen without compromising its visual appeal and intuitive layout.",
        },
        {
          name: "Scalability",
          description:
            "Dynamic websites can scale with the growth of a business or organization. Adding new pages, features, or functionalities can be relatively straightforward compared to static websites.",
        },
        {
          name: "Search functionality",
          description:
            "Dynamic websites can incorporate robust search functionality, making it easier for users to find specific information within the site. This is crucial for websites with large amounts of content or complex navigation structures.",
        },
        {
          name: "Security Risks",
          description:
            "Dynamic websites are more susceptible to security risks, such as SQL injection attacks and cross-site scripting (XSS), due to their interactive nature and reliance on databases. Robust security standards are critical to protect against these threats.",
        },
      ],
    },
    {
      id: 3,
      name: "E-Commerce",
      url: "/pricing/ecommerce",
      features: [
        {
          name: "Wishlist",
          description:
            "Wishlist is a very traditional feature for any e-commerce website. It allows the users to add the products to their wishlist so that they can consider buying them at a later point of time. Same must be applied for your mobile app too. Yes, if you are planning to create a mobile app for your e-commerce business, do not miss out the wishlist feature.",
        },
        {
          name: "Push Notifications",
          description:
            "Push Notifications are a way to alert your customers about new products/services, personalized marketing campaigns, flash sales, and discount offers. It is an effective way for your brand to communicate with the customers directly compared to email marketing. ",
        },
        {
          name: "Product filtering and sorting",
          description:
            "Product filtering allows customers to filter products based on different attributes. For example, a clothing store may use gender, size, colour etc., as its filtering options. The sorting allows viewing products in ascending or descending order of price and arrival.",
        },
        {
          name: "Multiple payment options",
          description:
            "Not all app users prefer the same way of making payments on e-commerce platforms. Some users select Cash on delivery, while some would like to buy items through online modes of payment like mobile wallets, credit/debit cards, net banking, and others. You can choose the most popular and trustable means of payment on your e-commerce site. Having multiple payment options allows users to select their preferred payment method for products which is another excellent option for an e-commerce app. Also, many banks provide different offers on their cards. The sales allow app users to buy products at discounts, attracting more customers to your electronic commerce site and app.",
        },
        {
          name: "Customer Support",
          description:
            "The online shopping experience differs greatly from traditional brick and mortar one as you cannot interact with your customers face-to-face. Providing e-commerce customer service via chatbox, email or phone helps you to engage with your customers and address their issues online. You can also improve your customer service offering a callback to assist users immediately or at a specific callback time. User benefit: Your customers can be sure that they won’t be left without an answer. Customer support guarantees a seamless user experience and makes people feel safe shopping with you.",
        },
        {
          name: "Offline Functionality",
          description:
            "This functionality offers an effective way to increase sales and boost customer experience. Offline availability works well for people who may not always have internet access. Even though users can’t make active purchases online, they can easily save items to buy later. ",
        },
        {
          name: "Shopping Cart",
          description:
            "It is a crucial feature for online shopping as it facilitates purchasing a product/service. Integrating the shopping cart functionality to your ecommerce app creates a positive user experience and enables customers the flexibility they desire as they make purchases. When the app adapts to suit the users’ preferences and behaviors, it is more likely to flourish. A shopping cart enables users to add products to the cart, and if they do not want to proceed to the checkout immediately, they can quickly return to it. Hence, they do not need to add products to the cart once again to make the final purchase, saving their time. ",
        },
        {
          name: "Augmented Reality",
          description:
            "Augmented Reality is shaping people’s shopping experiences and will become one of the main mobile e-commerce trends in 2020. It allows customers to interact with the product right from the screen of their mobile device. With the augmented reality feature, users can see a product and how it will look like in real context. Sephora and IKEA are great examples of integrating AR into mobile e-commerce apps. Although to build AR apps you will need to have specialized mobile app development expertise, the investment is sure to pay off. User benefit: Thanks to AR technology, customers can make a conscious decision before clicking a buy button without hesitation.",
        },
        {
          name: "Contactless Payments",
          description:
            "It makes the payment process a lot easier for the customer. A solution that gives the business and the customer control over payments and communication through text and the internet raises overall customer satisfaction to encourage repeat business. Customers like how simple it is to use their phone as a form of payment without having to download an app or remember another password to a website. Offering more convenient ways to pay boosts business revenue and satisfies customers at checkout with a straightforward click-to-pay method. Any company that wants to remain competitive in the modern digital world must prioritize removing customer friction.",
        },
        {
          name: "Loyalty Points",
          description:
            "Many online businesses offer their customers to install an app and use it every time they purchase at the online store. This option allows customers to collect points via a special app, which they can use to circulate promotion codes and discount offers. It motivates them to install and use the app at stores.",
        },
        {
          name: "Order management",
          description:
            "Package tracking provides users with a sense of trust and allows for time management of their busy lives. Consider having a page that shows the user their orders, and order history. This is a space where the user can report errors with a delivery or seek out more information on their order.",
        },
        {
          name: "Shipping Options",
          description:
            "This functionality allows app users to choose from available shipping options and displays the charges for each option and the estimated delivery time for each option. When users are allowed to pick from various shipping options, it leads to a positive user experience.",
        },
        {
          name: "Analytics",
          description:
            "Apart from delivering superior user experience, the core objective of an ecommerce mobile app is to earn revenues. Therefore, analytics is one important feature that your app should have. Understanding user behavior and buying pattern on your app is of utmost importance. Session time, cost of conversion, click-through rate, and accessing custom fields, all provide information about consumer behavior, leading to higher revenue streams.",
        },

        {
          name: "Easy checkout",
          description:
            "Placing orders with ease on your platform gives the best experience for your users. You can do so by asking for as much as less information from your users. Also, saving users’ residential addresses and preferred addresses helps them avoid entering their details every time they checkout. Try to select the best payment gateway for your platform that provides fast and secure payment transactions for your users. Delays or any problem when making payments can force users to avoid purchasing items from your online store. ",
        },
        {
          name: "Personalised user experience",
          description:
            "Retailers and eCommerce businesses everywhere are competing to offer enhanced personalisation right now. Apps are a great way to do this as you can use things like user data, account settings and their device features to craft personalised marketing campaigns and product recommendations. With an app, your customers can access their recommended products with just a quick tap – leading to more conversions.",
        },
        {
          name: "Rating and feedback",
          description:
            "One of the most common features of eCommerce apps is the rating and feedback feature. Sometimes it is mistaken that having feedback or reporting options is only beneficial for the users. Whatever feedback you get on your app can be used as a guide to improve the app. Keep in mind that the app is for users’ satisfaction and should be built as well as possible. Another option is the rating option which is usually represented with five stars. This is usually a publicly viewed rating option which is great for your app. Good ratings are seen as a way of recommendation ultimately increasing the number of users. Now that our list of best eCommerce app features is complete, it’s time for you to discover some of the most innovative eCommerce solutions given by Appinventiv to the global brands to enhance their digital presence.",
        },
        {
          name: "Simple Registration Process",
          description:
            "Most of the users don’t like to fill the long forms asking too much information. Make the registration process simple and put supreme required fields for easy action. Users hardly prefer the app which demands too much information for registration.",
        },
        {
          name: "Store Finder",
          description:
            "f your ecommerce site has physical stores or pick-up points, a store locator at the top bar can make them easy for your customers to find. They might look for the feature to confirm details like holiday closings, business hours or other location-specific amenities.",
        },
        {
          name: "Language options",
          description:
            "If your ecommerce site has multiple versions for different regions, then you can include language options to let users switch between different languages/regions.",
        },
        {
          name: "Search Bar",
          description:
            "If you optimise your search bar properly, it can become a powerful tool for conversions. Alongside helping your visitors to find what they need, a search bar will let you understand what people want from you.",
        },
        {
          name: "Loyalty program",
          description:
            "Mention things like reward points and other special offers for your long-time customers. Include a link that takes them to a more detailed offer page.",
        },

        {
          name: "Technical Support",
          description:
            "Ecommerce customer service is served to customers through a structured framework. The aim is to help shoppers via online platforms. We mean the eCommerce website that you are running to operate your business by the online platform.",
        },
      ],
    },
  ];
  return (
    <>
      <div className="pt-6 pb-3 flex flex-col xlg:flex-row xlg:flex-wrap gap-3 bg-slate-200/20">
        {websites.map((web, index) => (
          <div
            key={index}
            className={`${index === 1 && "xlg:mt-28"} ${
              index === 2 && "xl:mt-[224px]"
            } w-[300px] xs:w-[300px] sm:w-[405px] xlg:w-[290px] lg:w-[369px] xl:w-[320px] 2xl:w-[380px] 3xl:w-[440px] mx-auto`}
          >
            <div className="bg-white p-3 ring-1 ring-cyan-300 shadow rounded-md hover:scale-[1.01] duration-300">
              <div className="text-center font-semibold text-base py-3">
                {web.name}
              </div>
              <div className="py-4 border-y pl-3 font-medium flex justify-between">
                <div className="">Top Features</div>
                <Link
                  to={web.url}
                  className="mr-3 text-white bg-blue-500 hover:bg-blue-600 rounded-md px-2 py-0.5 duration-300"
                >
                  Get Started
                </Link>
              </div>
              <div className="p-3">
                {web.features.map((feature, i) => (
                  <div
                    key={i}
                    className="relative py-1 grid grid-cols-12 justify-between"
                  >
                    <div className="col-span-2 flex items-center justify-center text-green-700">
                      <ImCheckmark />
                    </div>
                    <div className="col-span-9">{feature.name}</div>
                    <div className="col-span-1 ">
                      <div
                        onMouseOver={(e) => handleShowFeatures(feature.name, e)}
                        onMouseOut={(e) => handleHideFeatures(feature.name, e)}
                        className="hover:scale-110 duration-300 hover:shadow hover:shadow-black shadow text-red-600 cursor-pointer flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full p-1"
                      >
                        ?
                      </div>
                    </div>
                    {features && featuresIndex === feature.name && (
                      <div className="absolute top-8 right-5 z-10 bg-gray-700/80 w-full">
                        {featuresIndex === feature.name && (
                          <div className="p-3 text-white text-justify ">
                            {feature.description}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
});

export default PriceCard;
