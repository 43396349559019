import React, { memo } from "react";
import FooterCard from "../components/footer/FooterCard";

const Footer = memo(() => {
  return (
    <>
      <FooterCard />
    </>
  );
});

export default Footer;
