import React, { memo } from "react";

const MultimediaContent = memo(() => {
  return (
    <>
      <div
        id="Multimedia"
        className="my-3 text-justify text-base flex flex-col gap-4 "
      >
        <div className="my-3 text-center text-lg font-semibold">
          Multimedia and interactive content
        </div>
        <div className="">
          Early websites had only text, and soon after, images. Web browser
          plug-ins were then used to add audio, video, and interactivity (such
          as for a rich Web application that mirrors the complexity of a desktop
          application like a word processor). Examples of such plug-ins are
          Microsoft Silverlight, Adobe Flash Player, Adobe Shockwave Player, and
          Java SE. HTML 5 includes provisions for audio and video without
          plugins.
        </div>
        <div className="">
          JavaScript is also built into most modern web browsers, and allows for
          website creators to send code to the web browser that instructs it how
          to interactively modify page content and communicate with the web
          server if needed. The browser's internal representation of the content
          is known as the Document Object Model (DOM).
        </div>
        <div className="">
          WebGL (Web Graphics Library) is a modern JavaScript API for rendering
          interactive 3D graphics without the use of plug-ins. It allows
          interactive content such as 3D animations, visualizations and video
          explainers to presented users in the most intuitive way.
        </div>
        <div className="">
          A 2010-era trend in websites called "responsive design" has given the
          best viewing experience as it provides a device-based layout for
          users. These websites change their layout according to the device or
          mobile platform, thus giving a rich user experience.
        </div>
      </div>
    </>
  );
});

export default MultimediaContent;
