import React, { memo } from "react";
import { Link } from "react-scroll";

const Logo = memo(() => {
  return (
    <>
      <Link
        to="home"
        spy={true}
        smooth={true}
        offset={-80}
        duration={1500}
        activeClass="notActive"
        className="flex gap-2 items-center justify-center cursor-pointer"
      >
        <img
          src="/favicon-96x96.png"
          alt="logo"
          className="h-9 w-9 rounded-full"
        />
        <div className="text-2xl">{process.env.REACT_APP_NAME}</div>
      </Link>
    </>
  );
});

export default Logo;
