import React, { memo } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import { toggleMenu } from "../../../redux/slices/toggleSlice";
import { useDispatch, useSelector } from "react-redux";
const ToggleMenu = memo(() => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.toggle.menu);
  return (
    <>
      <HiOutlineMenu onClick={() => dispatch(toggleMenu(!menu))} />
    </>
  );
});

export default ToggleMenu;
