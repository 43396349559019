import React, { memo } from "react";

const TechnologyHeader = memo(() => {
  return (
    <>
      <div className="bg-gray-300/80 py-2 text-center text-base rounded-sm">
        Technology
      </div>
    </>
  );
});

export default TechnologyHeader;
