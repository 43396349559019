import React, { memo } from "react";
import View from "./mvc/View";
import Controller from "./mvc/Controller";
import Model from "./mvc/Model";
import CardOutline from "./outline/CardOutline";
const TechnologyCard = memo(() => {
  return (
    <>
      <div className="mt-3 bg-slate-100 p-2">
        <div className="relative grid grid-cols-12 space-y-20 xlg:space-y-0 xlg:gap-10 w-[300px] sm:w-[405px] md:w-[476px] xlg:w-[600px] lg:w-[748px] xl:w-[1014px] mx-auto">
          <div className="col-span-12 flex justify-center">
            <View />
          </div>
          <div className="col-span-12 flex flex-col xlg:flex-row xlg:flex-wrap justify-around space-y-20 xlg:space-y-0 xlg:gap-0">
            <Controller />
            <Model />
          </div>
          <CardOutline />
        </div>
      </div>
    </>
  );
});

export default TechnologyCard;
