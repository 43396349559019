import React from "react";
import RightArrow from "../../../../svg/RightArrow";

function ModelOutline() {
  return (
    <>
      <div className="absolute top-[83px] left-[103px] sm:left-[115px] xlg:left-[100px] lg:left-28 xl:left-[115px] border-t-8 border-t-gray-300 w-7 sm:w-14 xlg:w-6 lg:w-11 xl:w-14"></div>
      <div className="absolute top-[75px] left-32 sm:left-[165px] xlg:left-[120px] lg:left-[155px] xl:left-[165px]">
        <RightArrow className="fill-gray-300 h-6" />
      </div>
      <div className="absolute top-[83px] right-20 sm:right-[90px] xlg:right-[75px] lg:right-[90px] xl:right-[90px] border-t-8 border-t-gray-300 w-[30px] sm:w-[60px] xlg:w-7 lg:w-12 xl:w-[60px]"></div>
      <div className="absolute top-[75px] right-[68px] sm:right-[85px] xlg:right-[66px] lg:right-20 xl:right-[85px]">
        <RightArrow className="fill-gray-300 h-6" />
      </div>
    </>
  );
}

export default ModelOutline;
