import React, { memo } from "react";
import { Link } from "react-router-dom";

const ProductLogo = memo(({ product, index }) => {
  return (
    <>
      <Link
        to={product.url}
        target="_blank"
        className="font-medium hover:underline rounded-md my-1"
      >
        <div
          className={`${index === 0 && "bg-cyan-300"} ${
            index === 1 && "bg-[rgba(29,175,175,1)]"
          } ${index === 2 && "bg-gray-300/80"} ${
            index === 3 && "bg-gray-700/80"
          } flex gap-1 justify-center items-center h-[26px] w-32 rounded-sm`}
        >
          <div className={`${index === 2 && "hidden"}`}>
            <img
              src={`uploads/products/logos/${product.logo}`}
              alt={product.logo}
              className="h-6 w-6 rounded-full"
            />
          </div>
          <div
            className={`${index === 2 && "font-extrabold font-lusitana "} ${
              index === 1 && "text-white"
            } ${index === 3 && "text-white"} text-xs`}
          >
            {product.name}
          </div>
        </div>
      </Link>
    </>
  );
});

export default ProductLogo;
