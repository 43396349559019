import { createSlice } from "@reduxjs/toolkit";
// export const rememberToken = localStorage.getItem("remember_token");
// export const token = localStorage.getItem("auth_token");
// export const email = localStorage.getItem("email");
// export const name = localStorage.getItem("auth_name");

export const initialState = {
  memberPhotoModalData: "",
};

const dataSlice = createSlice({
  name: "data",
  initialState: {
    memberPhotoModalData: initialState.memberPhotoModalData,
  },

  reducers: {
    storeMemberPhotoModalData(state, action) {
      state.memberPhotoModalData = action.payload;
    },
  },
});

export const { storeMemberPhotoModalData } = dataSlice.actions;
export default dataSlice.reducer;
