import React, { memo } from "react";
import AboutHeader from "./about header/AboutHeader";
import AboutCard from "./about card/AboutCard";

const AboutBody = memo(() => {
  return (
    <>
      <div className="my-3">
        <AboutHeader />
        <AboutCard />
      </div>
    </>
  );
});

export default AboutBody;
